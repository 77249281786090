import React, { useEffect, useState } from 'react';
import { Table,Input,Button, Checkbox, DatePicker, Form, Select, Switch } from 'antd';

export interface IAltisDataGridColumns
{
  title: string,
  key: string,
  placeHolder: string,
  inputType:string,
  dropdownItem?: IAltisDatagridDropdown[],
  hide?:boolean,
  render?: (value: any, record: any) => React.ReactNode;
  filter?: (value: any, record: any) => React.ReactNode;
}

interface IDataGridProp {
  data : Array<any>,
  total: number,
  columns : Array<IAltisDataGridColumns>,
  onChange: (e : any) => void
}
export interface IAltisDatagridDropdown {
  id:number,
  name:string
}
let dataGridInfo :any  = {
  pageSize: 10,
  page: 1,
};


const AltisDataGrid: React.FunctionComponent<IDataGridProp> = props => {
const [dataGridColumns , setDataGridColumns] = useState<any>([]);


useEffect(()=>{
  setDataGridColumns([])
  props.columns.map((item) => {
    if ( ! (item.hide != null && item.hide == false))
    {
      if(item.inputType === 'input')
      {
        setDataGridColumns((prev) => [...prev ,InputType(item)])
      }
      else if(item.inputType==='checkbox')
      {
        setDataGridColumns((prev) => [...prev ,CheckboxType(item)])
      }
      else if(item.inputType === 'date')
      {
        setDataGridColumns((prev) => [...prev ,DateType(item)])
      }
      else if(item.inputType === 'operations')
      {
        setDataGridColumns((prev) => [...prev ,OperationsType(item)])
      }
      else if(item.inputType === 'dropdown')
      {
        setDataGridColumns((prev) => [...prev ,DropdownType(item)])
      }
      else if(item.inputType === 'switch')
      {
        setDataGridColumns((prev) => [...prev ,SwitchType(item)])
      }
      else if(item.inputType === 'custom')
      {
        setDataGridColumns((prev) => [...prev ,CustomType(item)])
      }
      else
      {
        setDataGridColumns((prev) => [...prev ,NoFilterType(item)])
      }
    }
  });
},[props.data])

const update = (e) => {
  dataGridInfo = { ...dataGridInfo, ...e };
  props.onChange(dataGridInfo);
  console.log(dataGridInfo);
}

const objectToNull=(e:string) =>
{
  dataGridInfo[e] = null;
  props.onChange(dataGridInfo);
}

const dropdownChange = (key,value) =>{
  dataGridInfo[key] = value;
  props.onChange(dataGridInfo);
}

const InputType=(item:IAltisDataGridColumns)=>{
  return {
  title: item.title,
  key: item.key,       // uniq id oluşturur
  dataIndex: item.key, // veriyi çeker
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 20 }} key={item.key}>
      <Form onFinish={update}>
        <Form.Item name={item.key}>
          <Input placeholder={item.placeHolder} style={{ width: 188, marginBottom: 8, display: 'block' }} />
        </Form.Item>
        <Form.Item style={{ textAlign: 'center' }}>
        
          <Button type="primary" htmlType="submit" size="small" style={{ width: 100 }}> Filtrele </Button>
         
        </Form.Item>
    </Form>
    </div>
  )
}
}

const CheckboxType = (item:IAltisDataGridColumns) =>{
  return {
    title: item.title,
    key: item.key,       // uniq id oluşturur
    dataIndex: item.key, // veriyi çeker
    filterDropdown: ({ confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Form onFinish={update}>
          <Form.Item name={item.key} initialValue={false} valuePropName="checked">
            <Checkbox> {item.placeHolder} </Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
          
            <Button type="primary" htmlType="submit" size="small" style={{ width: 90 }}> Filtrele </Button>
           
          </Form.Item>
        </Form>
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" size="small" onClick={()=>objectToNull(item.key)} style={{ width: 90 }}> Temizle </Button>
          </div>
      </div>
    ),
    render: (text, record) => {
      return text == true ? <Checkbox checked={true} /> : <Checkbox checked={false} />;
    },
  };
}

const SwitchType = (item:IAltisDataGridColumns) =>{
  return {
    title: item.title,
    key: item.key,
    dataIndex: item.key,
    filterDropdown: ({ confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Form onFinish={update}>
          <Form.Item name={item.key} initialValue={false} valuePropName="checked">
            <Checkbox> {item.placeHolder} </Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" size="small" style={{ width: 90 }}> Filtrele </Button>
          </Form.Item>
        </Form>
        <div style={{ textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" size="small" onClick={()=>objectToNull(item.key)} style={{ width: 90 }}> Temizle </Button>
          </div>
      </div>
    ),
    render: item.render
  };
}

const OperationsType= (item:IAltisDataGridColumns) => {
  return {
    title: item.title,
    key: item.key,       // uniq id oluşturur
    dataIndex: item.key, // veriyi çeker
    render: item.render
  };
}

const DateType = (item:IAltisDataGridColumns)=>{
  return  {
    title: item.title,
    key: item.key,       // uniq id oluşturur
    dataIndex: item.key, // veriyi çeker
    filterDropdown: ({ confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Form onFinish={update}>
          <Form.Item name={item.key+"_start"}>
            <DatePicker showTime placeholder={`Başlangıç Tarihini Seçin`} style={{ width: 188, marginBottom: 8, display: 'block' }} />
          </Form.Item>
          <Form.Item name={item.key+"_finish"}>
            <DatePicker showTime placeholder={`Bitiş Tarihini Seçin`} style={{ width: 188, marginBottom: 8, display: 'block' }} />
          </Form.Item>
          <Form.Item >
            <Button type="primary" htmlType="submit" size="small" style={{ width: 90 }}> Filtrele </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

const DropdownType=(item:IAltisDataGridColumns)=>{
  return {
  title: item.title,
  key: item.key,
  dataIndex: item.key,
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 20 }} key={item.key}>
        <Select
          placeholder={item.placeHolder}
          allowClear
          className='rounded-select'
          style={{ borderRadius: '20px !important' }}
          onChange={(value) => dropdownChange(item.key,value)}
        >
          {
            item.dropdownItem.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))
          }
        
        </Select>
    </div>
  ),
  render: item.render === null ? <div>{item.key}</div> : item.render
}
}

const NoFilterType=(item:IAltisDataGridColumns)=>{
  return {
  title: item.title,
  key: item.key,
  dataIndex: item.key,
}
}
const CustomType=(item:IAltisDataGridColumns)=>{
  return {
  title: item.title,
  key: item.key,
  dataIndex: item.key,
  render: item.render,
  filterDropdown: item.filter
}
}
const handlePage = (page, pageSize) => 
{
  dataGridInfo.page = page;
  dataGridInfo.pageSize = pageSize;
  props.onChange(dataGridInfo);
}

  return (
    <>
      <Table 
        pagination={{
          showSizeChanger: true,
          total: props.total,
          onChange: handlePage,
        }}
        columns={dataGridColumns}
        dataSource={props.data}
      />
    </>
  )
}

export default AltisDataGrid;
