import React from 'react';

import { IPageData, IPageProps } from '../../../interfaces/page-data';
import {ParcelExcel} from "./ParseExcel";


const ImportUsers: React.FunctionComponent<IPageProps> = props => {

    const pageData: IPageData = {
        title: 'Dashboard',
        loaded: false,
        breadcrumbs: [
            {
                title: 'Home',
                route: 'dashboard'
            },
            {
                title: 'Dashboard'
            }
        ]
    };


    return (
        <>
            <ParcelExcel />
        </>
    );
};

export default ImportUsers;
