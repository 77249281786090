import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Alert, Button, Form, Input, Space } from 'antd';
import { IUser } from '../../../interfaces/user';
import HTTPService from '../../../utils/makerequest';
import { toast } from 'react-hot-toast';
import { IAuthentication } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';

const SingInForm: React.FC<any> = (props) => {
  const [visible, setVisible] = useState(false);
  const [submit, setSubmit] = useState(false);
  const handleClose = () => {
    setVisible(false);
  };

  useEffect(()=>
  {
    const loader = new StateLoader().getAuthState();
    if(loader !== null){
      window.location.replace('/vertical/dashboard/');
    }
  },[]);
  const login = (e) => {
    let user: IUser = { userEmailMain: e.username, userPass: e.password } as IUser;
    toast.loading('Loading...');
    HTTPService.LOGIN(`/Login?email=${user.userEmailMain}&pass=${user.userPass}`)
      .then((response) => {

        if(response.status == 200){
          let auth: IAuthentication = {
            userID: response.data.userID,
            userRole: response.data.userRole,
            userCompanyID: response.data.userCompanyID,
            userApiKey: response.data.userApiKey,
            userFirstName: response.data.userFirstName,
            userMiddleName: response.data.userMiddleName,
            userLastName: response.data.userLastName,
            userPictureURL: response.data.userPictureURL,
          };
          new StateLoader().setAuthState(auth);
        }
        else{
          setVisible(true);
          setSubmit(false);
        }
      })
      .catch((response) => {
        setVisible(true);
        setSubmit(false);
        return;
      });
  };

  return (
    <Form style={{ width: '100%' }} onFinish={login}>
      {visible && (
        <Space direction='vertical' style={{ width: '100%', marginBottom: '20px' }}>
          <Alert
            message='Kullanıcı adı veya parola hatalı!'
            type='error'
            closable={true}
            afterClose={handleClose}
          />
        </Space>
      )}
      <Form.Item
        name='username'
        rules={[{ required: true, message: 'Lütfen bir kullanıcı adı giriniz!' }]}>
        <Input placeholder='Email' />
      </Form.Item>
      <Form.Item
        name='password'
        rules={[{ required: true, message: 'Lütfen bir parola giriniz!' }]}>
        <Input.Password
          bordered={false}
          placeholder='Parola'
          visibilityToggle={false}
          // iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
      <Form.Item>
        <div className='span d-flex justify-content-between'>
          <NavLink to='/public/forgot-password' className='link pl-sm-0'>
            Parolamı Unuttum
          </NavLink>
          <Button
            type='primary'
            htmlType='submit'
            loading={submit}
            onClick={() => setSubmit(true)}>
            Giriş Yap
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

//const WrappedForm = Form.create({ name: 'sign-in' })(SingInForm);

export default SingInForm;
