import React, { useEffect, useRef, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Select,
  Space,
} from 'antd';
import imageCompression from 'browser-image-compression';
import userAvatar from '../../../assets/content/default-profile.jpg';

import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { UserOutlined } from '@ant-design/icons';
import HTTPService from '../../../utils/makerequest';
import { IUser, IUserUpdate } from '../../../interfaces/user';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { useParams } from 'react-router';
import { IAuthentication } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';
import { ImageBaseURL } from '../../../utils/Constants';

const { Option } = Select;

const UserRoleForm: React.FC<any> = (props) => {
  const userId = useParams<{ id?: string }>().id;

  const [userRoleState, setUserRoleState] = useState(props?.profileData?.userRole);

  const authInfo :IAuthentication = new StateLoader().getAuthState();
  useEffect(() => {
    setUserRoleState(props?.profileData?.userRole);
  }, [props?.profileData?.userRole]);

  const UserRoless = {
    2: 'Yönetici',
    1: 'Editör',
    0: 'Üye',
  };
  const setNewRole = (e) => {
    setUserRoleState(e);
  };

  const setRole = (e) => {
    HTTPService.PUT(`/user/setRole?id=${userId}&userRole=${userRoleState}`,{})
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success('Rol Güncellendi');
        } else {
          toast.error('Rol Güncellenemedi');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };

  return (
    <Form
      onFinish={setRole}
      style={{
        display: 'grid',
        gridTemplateColumns: '2fr 2fr',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Form.Item
        name='userRole'
        style={{
          display: 'inline-block',
          width: 'calc(50% - 12px)',
        }}>
        ROL SEÇİNİZ:
        <Select onSelect={setNewRole} value={UserRoless[userRoleState]}>
          <Option value='0'>Üye</Option>
          <Option value='1'>Editör</Option>
          <Option value='2'>Yönetici</Option>
        </Select>
      </Form.Item>

      <Form.Item style={{ gridColumn: '1 / -1', marginTop: '20px' }}>
        <Button type='primary' htmlType='submit'>
          Kaydet
        </Button>
      </Form.Item>
    </Form>
  );
};

//const WrappedPasswordForm = Form.create({ name: 'password' })(PasswordForm);

const UserForm: React.FC<any> = ({
  props,
  data,
  userData,
  userPicture,
  userCoverURLProp,
  selectedItem,
  selectedItemName,
  companyData,
}) => {
  const [birthdate, setBirthdate] = useState(null);
  const [pselectedItemName, setSelectedItemName] = useState();
  const [pselectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    setSelectedItem(selectedItem);
    setSelectedItemName(selectedItemName);
  }, [selectedItemName, selectedItem]);

const authInfo : IAuthentication = new StateLoader().getAuthState();

  const userMenu = (
    <Menu
      style={{
        width: '200px',
        fontSize: '22px',
      }}>
      {companyData.map((element, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              setSelectedItem(element?.companyID);
              setSelectedItemName(element?.companyName);
            }}>
            {element.companyName}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const update = (e) => {
    var birthDayval;
    if (birthdate != null) {
      birthDayval = moment(birthdate).local().format('YYYY-MM-DD');
    } else {
      birthDayval = null;
    }
    if (authInfo.userRole !== 2) {
      setSelectedItem(data?.userCompanyID);
    }

    let user: IUserUpdate = {
      userID: userData,
      userTitle: e.userTitle,
      userFirstName: e.userFirstName,
      userMiddleName: e.userMiddleName,
      userLastName: e.userLastName,
      userWorkMobile: e.userWorkMobile,
      userPhoneWorkExtension: e.userPhoneWorkExtension,
      userPersonalMobile: e.userPersonalMobile,
      userPhoneMobile2: e.userPhoneMobile2,
      userPhoneHome: e.userPhoneHome,
      userEmailMain: e.userEmailMain,
      userEmailAdditional: e.userEmailAdditional,
      userAddress: e.userAddress,
      userBirthdate: birthDayval,
      userLinkedinURL: e.userLinkedinURL,
      userInstagramURL: e.userInstagramURL,
      userFacebookURL: e.userFacebookURL,
      userTwitterURL: e.userTwitterURL,
      userGithub: e.userGithub,
      userYoutube: e.userYoutube,
      userIBAN: e.userIBAN,
      userSocialMediaURL: e.userSocialMediaURL,
      userPictureURL: userPicture,
      userCoverURL: userCoverURLProp,
      userApiKey: authInfo.userApiKey,
      userCompanyID: pselectedItem,
    } as IUserUpdate;

    HTTPService.PUT(`/user`, user)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success('Kayıt Güncellendi');
          setTimeout(function () {
            if (authInfo.userRole === 0) {
              window.location.replace('/vertical/dashboard');
            } else {
              window.location.replace('/vertical/user-list');
            }
          }, 1000);
        } else {
          toast.error('Kayıt Güncellenemedi');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };

  function handleDateChange(date, dateString) {
    const selectedDate = moment(dateString).local().format('YYYY-MM-DD');
    //moment(datetimepickerValue).add(1, 'day').local().format('YYYY-MM-DD');

    setBirthdate(moment(selectedDate));
  }
  useEffect(() => {
    if (data?.userBirthdate != null) {
      const selectedDate = moment(data?.userBirthdate).local().format('YYYY-MM-DD');
      setBirthdate(moment(selectedDate));
    }
  }, [data.userBirthdate]);

  return (
    <Form
      layout='vertical'
      onFinish={update}
      fields={[
        {
          name: ['userTitle'],
          value: data.userTitle,
        },
        {
          name: ['userFirstName'],
          value: data.userFirstName,
        },
        {
          name: ['userMiddleName'],
          value: data.userMiddleName,
        },
        {
          name: ['userLastName'],
          value: data.userLastName,
        },
        {
          name: ['userBirthdate'],
          value: data?.userBirthdate,
        },
        {
          name: ['userEmailMain'],
          value: data.userEmailMain,
        },
        {
          name: ['userEmailAdditional'],
          value: data.userEmailAdditional,
        },
        {
          name: ['userIBAN'],
          value: data.userIBAN,
        },
        {
          name: ['userPersonalMobile'],
          value: data.userPersonalMobile,
        },
        {
          name: ['userPhoneMobile2'],
          value: data.userPhoneMobile2,
        },
        {
          name: ['userPhoneHome'],
          value: data.userPhoneHome,
        },
        {
          name: ['userAddress'],
          value: data.userAddress,
        },
        {
          name: ['userLinkedinURL'],
          value: data.userLinkedinURL,
        },
        {
          name: ['userInstagramURL'],
          value: data.userInstagramURL,
        },
        {
          name: ['userFacebookURL'],
          value: data.userFacebookURL,
        },
        {
          name: ['userTwitterURL'],
          value: data.userTwitterURL,
        },
        {
          name: ['userGithub'],
          value: data.userGithub,
        },
        {
          name: ['userYoutube'],
          value: data.userYoutube,
        },
        {
          name: ['userSocialMediaURL'],
          value: data.userSocialMediaURL,
        },
        {
          name: ['userCoverURL'],
          value: data.userCoverURL,
        },
        {
          name: ['userCompany'],
          value: data.userCompany,
        },
      ]}>
      {authInfo.userRole === 2 && (
        <Form.Item name='userCompany'>
          <Space>
            <Dropdown overlay={userMenu}>
              <Button>Şirket Seçin:</Button>
            </Dropdown>
            {pselectedItemName}
          </Space>
        </Form.Item>
      )}
      <Form.Item label='Ünvan' name='userTitle'>
        <Input placeholder='Doktor' />
      </Form.Item>

      <Form.Item
        label='Ad'
        name='userFirstName'
        rules={[
          { required: true, message: 'Lütfen adınızı girin.' },
          { type: 'string', min: 3, message: 'Ad en az 3 karakter olmalıdır' },
        ]}>
        <Input placeholder='Mehmet' />
      </Form.Item>

      <Form.Item
        label='Ortanca Ad'
        name='userMiddleName'
        rules={[
          { required: false, message: 'Ortanca Ad girin.' },
          { type: 'string', min: 2, message: 'Ortanca Ad en az 2 karakter olmalıdır' },
        ]}>
        <Input placeholder='Ali' />
      </Form.Item>

      <Form.Item
        label='Soyad'
        name='userLastName'
        rules={[
          { required: true, message: 'Lütfen soyadınızı girin.' },
          { type: 'string', min: 3, message: 'Soyad en az 3 karakter olmalıdır' },
        ]}>
        <Input placeholder='Yılmaz' />
      </Form.Item>

      <Form.Item
        label='Doğum Yılı'
        style={{
          display: 'inline-block',
          width: 'calc(50% - 12px)',
        }}>
        <Space direction='horizontal'>
          <DatePicker
            name='userBirthdate'
            onChange={handleDateChange}
            value={birthdate}
            picker='date'
            allowClear={true}
            style={{
              display: 'flex',
              width: '200%',
            }}
          />
        </Space>
      </Form.Item>

      <Form.Item
        label='E-Posta'
        name='userEmailMain'
        rules={[
          { required: true, message: 'Lütfen e-posta adresinizi girin.' },
          { type: 'email', message: 'Lütfen geçerli bir e-posta adresi girin.' },
        ]}>
        <Input placeholder='name@company.com' />
      </Form.Item>

      <Form.Item
        label='İkincil E-Posta'
        name='userEmailAdditional'
        rules={[
          { required: false, message: 'Lütfen e-posta adresinizi girin.' },
          { type: 'email', message: 'Lütfen geçerli bir e-posta adresi girin.' },
        ]}>
        <Input placeholder='name@company.com' />
      </Form.Item>

      <Form.Item label='IBAN' name='userIBAN'>
        <Input placeholder='TR0600000000000' />
      </Form.Item>

      <Form.Item
        label='Telefon Numarası'
        name='userPersonalMobile'
        rules={[
          { required: false, message: 'Lütfen telefon numaranızı girin.' },
          { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
        ]}>
        <Input placeholder='+90XXXXXXXXXX' />
      </Form.Item>

      <Form.Item
        label='İkincil Telefon Numarası'
        name='userPhoneMobile2'
        rules={[
          { required: false, message: 'Lütfen telefon numaranızı girin.' },
          { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
        ]}>
        <Input placeholder='+90XXXXXXXXXX' />
      </Form.Item>

      <Form.Item
        label='İş Telefonu'
        name='userPhoneHome'
        rules={[
          { required: false, message: 'Lütfen telefon numaranızı girin.' },
          { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
        ]}>
        <Input placeholder='+90XXXXXXXXXX' />
      </Form.Item>

      <Form.Item
        label='Adres'
        name='userAddress'
        rules={[
          { required: false, min: 15, message: 'Adresinizi en az 15 karakter ile ifade ediniz.' },
        ]}>
        <Input.TextArea placeholder='71 Pilgrim Avenue Chevy Chase, MD 20815' />
      </Form.Item>

      <Form.Item
        label='LinkedIn'
        name='userLinkedinURL'
        rules={[
          {
            required: false,
            min: 5,
            message: 'Lütfen geçerli bir LinkedIn URL adresinizi giriniz.',
          },
          // {
          //   pattern: /^(www\.)?linkedin\.com\/[a-zA-Z0-9._-]+$/i,
          //   message: 'Lütfen geçerli bir LinkedIn URL adresi giriniz. (www.linkedin.com/username)',
          // },
        ]}>
        <Input placeholder='www.linkedin.com/username' />
      </Form.Item>

      <Form.Item
        label='Instagram'
        name='userInstagramURL'
        rules={[
          {
            required: false,
            min: 5,
            message: 'Lütfen geçerli bir Instagram URL adresinizi giriniz.',
          },
          // {
          //   pattern: /^(www\.)?instagram\.com\/[a-zA-Z0-9._-]+$/i,
          //   message:
          //     'Lütfen geçerli bir Instagram URL adresi giriniz. (www.instagram.com/username)',
          // },
        ]}>
        <Input placeholder='www.instagram.com/username' />
      </Form.Item>

      <Form.Item
        label='Facebook'
        name='userFacebookURL'
        rules={[
          {
            required: false,
            min: 5,
            message: 'Lütfen geçerli bir Facebook URL adresinizi giriniz.',
          },
          // {
          //   pattern: /^(www\.)?facebook\.com\/[a-zA-Z0-9._-]+$/i,
          //   message: 'Lütfen geçerli bir Facebook URL adresi giriniz. (www.facebook.com/username)',
          // },
        ]}>
        <Input placeholder='www.facebook.com/username' />
      </Form.Item>

      <Form.Item
        label='Twitter'
        name='userTwitterURL'
        rules={[
          {
            required: false,
            min: 5,
            message: 'Lütfen geçerli bir Twitter URL adresinizi giriniz.',
          },
          // {
          //   pattern: /^(www\.)?twitter\.com\/[a-zA-Z0-9._-]+$/i,
          //   message: 'Lütfen geçerli bir Twitter URL adresi giriniz. (www.twitter.com/username)',
          // },
        ]}>
        <Input placeholder='www.twitter.com/username' />
      </Form.Item>

      <Form.Item
        label='Github'
        name='userGithub'
        rules={[
          { required: false, min: 5, message: 'Lütfen geçerli bir Github URL adresinizi giriniz.' },
          // {
          //   pattern: /^(www\.)?github\.com\/[a-zA-Z0-9._-]+$/i,
          //   message: 'Lütfen geçerli bir Github URL adresi giriniz. (www.github.com/username)',
          // },
        ]}>
        <Input placeholder='www.github.com/username' />
      </Form.Item>

      <Form.Item
        label='Youtube'
        name='userYoutube'
        rules={[
          {
            required: false,
            min: 5,
            message: 'Lütfen geçerli bir Youtube URL adresinizi giriniz.',
          },
          // {
          //   pattern: /^(www\.)?youtube\.com\/[a-zA-Z0-9._-]+$/i,
          //   message: 'Lütfen geçerli bir Youtube URL adresi giriniz. (www.youtube.com/username)',
          // },
        ]}>
        <Input placeholder='www.youtube.com/username' />
      </Form.Item>

      <Form.Item label='Özel URL' name='userSocialMediaURL'>
        <Input placeholder='www.example.com/' />
      </Form.Item>

      <div className='d-flex justify-content-between'>
        <Button
          danger
          ghost
          onClick={() => {
            if (authInfo.userRole === 0) {
              window.location.replace('/vertical/dashboard');
            } else {
              window.location.replace('/vertical/user-list');
            }
          }}>
          İptal
        </Button>
        <Button type='primary' htmlType='submit'>
          Değişiklikleri Kaydet
        </Button>
      </div>
    </Form>
  );
};

const PageEditAccount: React.FC<IPageProps> = (props) => {
  const { onSetPage } = props;

  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState<any>(userAvatar);
  const [coverUrl, setCoverUrl] = useState<any>(userAvatar);
  const [companyData, setCompanyData] = useState([]);
  const [selectedItemName, setSelectedItemName] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);

  const fileInput = useRef(null);
  const fileInput2 = useRef(null);

  const userId = useParams<{ id?: string }>().id;
  const [profileData, setProfileData] = useState<IUserUpdate>({});
  const UserRoles = {
    ADMIN: 2,
    EDITOR: 1,
    SUBSCRIBER: 0,
  };

  const authInfo : IAuthentication = new StateLoader().getAuthState();

  const canEditProfile = (userRole, profileOwnerId, profileOwnerRole) => {
    if (userRole === UserRoles.ADMIN) {
      return true; // Admin herkesin profilini düzenleyebilir
    } else if (userRole === UserRoles.EDITOR) {
      if (profileOwnerId === authInfo.userID || userRole >= profileOwnerRole) {
        return true; // Editör kendi profilini düzenleyebilir, Subscriber'ların profilini de düzenleyebilir
      } else {
        return false; // Editör Admin'in profilini düzenleyemez
      }
    } else if (userRole === UserRoles.SUBSCRIBER) {
      if (profileOwnerId === authInfo.userID) {
        return true; // Subscriber kendi profilini düzenleyebilir
      } else {
        return false; // Subscriber başka bir kullanıcının profilini düzenleyemez
      }
    }
  };

  const canShowEditProfile = canEditProfile(
    authInfo.userRole,
    profileData.userID,
    profileData.userRole
  );

  const pageData: IPageData = {
    title: 'Edit account',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'User Pages',
        route: 'dashboard',
      },
      {
        title: 'Edit Account',
      },
    ],
  };

  const fetchCompanyInfo = () => {
      HTTPService.GET('/Company/name').then((response) => {
      if (!response.data) {
        return;
      }
      let tmp = response.data;
      if (tmp.length < 1) {
        setCompanyData([]);
        return;
      }

      setCompanyData(tmp);
      const companyID = profileData?.userCompanyID;
      const companyName = profileData?.companytb?.companyName;
      setSelectedItem(companyID);
      setSelectedItemName(companyName);
    });
  };

  const onFileChanged = (inputValue: any, fileName) => {
    const file: File = inputValue.target.files[0];
    const reader: FileReader = new FileReader();

    reader.onloadend = () => {
      if (fileName === 'cover') {
        setCoverUrl(reader.result);
      } else {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    onSetPage(pageData);
    getUserDetails(userId);
    setAvatar(profileData.userPictureURL);
    setCoverUrl(profileData.userCoverURL);
    fetchCompanyInfo();
  }, [selectedItem, selectedItemName]);

  const getUserDetails = (id: string) => {
    setLoading(true);
    HTTPService.GET(`/user?guid=${id}`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        setProfileData(response.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  interface IUpdateModel {
    userId: string,
    apiKey: string,
    imageId: number,
    image: File
  }

  useEffect(() => {
    setAvatar(profileData.userPictureURL);
    setCoverUrl(profileData.userCoverURL);
  }, [profileData]);

  const clearPhoto = async(areaToBeCleaned ) => {
    const imageId = areaToBeCleaned === "deleteProfile" ? 0 : 1;
    const imageData : IUpdateModel= {
      userId: profileData.userID,
      apiKey: authInfo.userApiKey,
      imageId: imageId,
      image: null ,
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(imageData)) {
      formData.append(key, value);
    }
    
    try {

      HTTPService.PUTForm(`/user/putImage`, formData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success('Görsel Güncellendi');
          } else {
            toast.error('Görsel Güncellenemedi');
          }
        })
        .catch((error) => {
          toast.error('There was an error!', error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  const handleFileRead = async (event, uploadtype) => {
    const imageFile = event.target.files[0];
    const maxSizeMB = 1;
    const options = {
      maxSizeMB,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    var imageId;
    const compressedFile = await imageCompression(imageFile, options);
    if (uploadtype === 'cover') {
      profileData['userCoverURL'] = compressedFile;
      imageId = 1;
    }
    if (uploadtype === 'profile') {
      profileData['userPictureURL'] = compressedFile;
      imageId = 0;
    }

    profileData['userApiKey'] = authInfo.userApiKey;
    const imageData : IUpdateModel= {
      userId: profileData.userID,
      apiKey: authInfo.userApiKey,
      imageId: imageId,
      image: imageFile ,
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(imageData)) {
      formData.append(key, value);
    }
    
    try {

      HTTPService.PUTForm(`/user/putImage`, formData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success('Görsel Güncellendi');
          } else {
            toast.error('Görsel Güncellenemedi');
          }
        })
        .catch((error) => {
          toast.error('There was an error!', error);
        });
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div className='row justify-content-center'>
      {canShowEditProfile && (
        <div className='col col-12 col-xl-7'>
          <Card title='Kullanıcı Fotoğrafı'>
            <div className='d-sm-flex align-items-center w-100 elem-list flex-column'>
              <Avatar src={ImageBaseURL + avatar} size={100} className='mb-4' />
              <div className='d-sm-flex align-items-center'>
                <div className='p-1'>
                  <Button
                    type='primary'
                    htmlType='button'
                    ghost
                    icon={<UserOutlined />}
                    onClick={(e) => {
                      fileInput.current.click();
                    }}>
                    Profil Fotoğrafı Değiştir
                  </Button>
                </div>
                <div className='p-1'>
                  <Button
                    type='primary'
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      setAvatar(null);
                      clearPhoto('deleteProfile');
                    }}>
                    Profil Fotoğrafını Sil
                  </Button>
                </div>
                <input
                  ref={fileInput}
                  type='file'
                  accept='image/*'
                  onChange={(e) => {
                    onFileChanged(e, 'profile');
                    handleFileRead(e, 'profile');
                  }}
                  hidden
                />
              </div>

              <Avatar src={ImageBaseURL + coverUrl} size={100} className='mb-4' />
              <div className='d-sm-flex align-items-center'>
                <div className='p-1'>
                  <Button
                    type='primary'
                    htmlType='button'
                    ghost
                    onClick={(e) => {
                      fileInput2.current.click();
                    }}>
                    Cover Fotoğrafı Değiştir
                  </Button>
                </div>
                <div className='p-1'>
                  <Button
                    type='primary'
                    danger
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                      setCoverUrl(null);
                      clearPhoto('deleteCover');
                    }}>
                    Cover Fotoğrafını Sil
                  </Button>
                </div>
              </div>
              <input
                ref={fileInput2}
                type='file'
                accept='image/*'
                onChange={(e) => {
                  onFileChanged(e, 'cover');
                  handleFileRead(e, 'cover');
                }}
                hidden
              />
            </div>
          </Card>
          {(authInfo.userRole === 1 || authInfo.userRole === 2) && (
            <Card title='Kullanıcı Rolü' className='mb-4'>
              <UserRoleForm profileData={profileData} userData={userId} companyData={companyData} />
            </Card>
          )}
          {loading && (
            <Card title='Kullanıcı Bilgileri'>
              <UserForm
                data={profileData}
                userData={userId}
                userPicture={avatar}
                userCoverURLProp={coverUrl}
                selectedItem={selectedItem}
                selectedItemName={selectedItemName}
                setSelectedItemName={setSelectedItemName}
                setSelectedItem={setSelectedItem}
                companyData={companyData}
              />
            </Card>
          )}
        </div>
      )}
    </div>
  );
};

export default PageEditAccount;
