import React, { Suspense, useEffect, useState } from 'react';
import { Avatar, Button, Card, Result, Space, Switch, Tooltip, Typography,Select} from 'antd';
import { useParams } from 'react-router';
import { IPageProps } from '../../interfaces/page-data';
import HTTPService from '../../utils/makerequest';
import { ISetCounter, IUser, IUserUpdate } from '../../interfaces/user';
import VCardGenerator from '../Users/VCardGenerator';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ShareAltOutlined } from '@ant-design/icons';
import toast from 'react-hot-toast';
import { ImageBaseURL } from '../../utils/Constants';
import moment from 'moment';
import '../ViewProfile/ViewProfile.css';
import QRCode from 'qrcode.react';
import { StateLoader } from '../../redux/StateLoader';
import { IAuthentication } from '../../interfaces/authentication';


const PageViewProfile: React.FC<IPageProps> = (props) => {
    const userTmp = useParams<{ id?: string }>().id;
    const userId = userTmp.replace("+", "");

    const [profileData, setProfileData] = useState<IUserUpdate>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [userLanguage, setUserLanguage] = useState<boolean>( window.navigator.language.includes("en") ? true : false);
    const [isEditProfile, setIsEditProfile] = useState<boolean>(false);


    const stateLoader = new StateLoader();
    const { Option } = Select;

    const getUserDetails = async (id: string) => {
        await HTTPService.GET(`/user?guid=${id}`)
            .then(async (response) => {
                if (!response.data) {
                    return;
                }
                console.log(response)
                if(response.status == 200)
                {
                    await setProfileData(response.data);
                    canEditProfile(response.data);
                }
                else{
                    getUserDetails(id)
                }
            })
            .then(() => setLoading(false));
    };
      
    const  setSocialLink = async () => {
        const PageCounter: ISetCounter = {
            countType: 3,
            userId: userId,
            operatingSystem: navigator.platform,
        };

        HTTPService.PUT('/Count/social', PageCounter).then((res) => {
            console.log('page count increased');
        })
        .catch(err => console.log(err));
    };

    const setCount = async () =>{
        const PageCounter: ISetCounter = {
            countType: 1,
            userId: userId,
            operatingSystem: navigator.platform,
        };
        HTTPService.PUT('/Count/page', PageCounter).then((res) => {
            console.log('page count increased');
        })
        .catch(err=> console.log(err));
    };

    const onChangeActive = (record: IUser, event) => {
        var authInfo : IAuthentication = stateLoader.getAuthState();
        HTTPService.PUT(
            `/user/isVisible?ApiKey=${authInfo.userApiKey}&id=${record.userID
            }&isVisible=${event.toString()}`,
            event
        )
            .then((res) => {
                if (res.statusText === 'OK') {
                    toast.success('Updated.');
                }
            })
            .catch((error) => {
                toast.error('There was an error!', error);
            });
    };

    const getBirthday = () => {
        if (profileData?.userBirthdate != null) {
            const selectedDate = moment(profileData?.userBirthdate).local().format('YYYY-MM-DD');
            return selectedDate;
        } else {
            return null;
        }
    };
    const UserRoles = {
        ADMIN: 2,
        EDITOR: 1,
        SUBSCRIBER: 0,
    };

    const canEditProfile = (value) => {
        var authInfo: IAuthentication = stateLoader.getAuthState();
        if(authInfo === null)
        {
            return;
        }
        if (authInfo.userRole === UserRoles.ADMIN) 
        {
            setIsEditProfile(true);
        }
        else if (authInfo.userRole === UserRoles.SUBSCRIBER) 
        {

            if (value.userID === authInfo.userID) 
            {
                setIsEditProfile(true);
            }
        }
    };

    const notify = () => toast.success('Panoya Kopyalandı');

    useEffect(() => {
        getUserDetails(userId);
        setCount();
    }, []);

    const languageList = [
        'TR',
        'EN'
      ];

    return (
        <>
                {loading ? (
                    <div>
                        {
                            userLanguage ? "LOADING...." :  "YÜKLENİYOR..." 
                        }
                        </div>
                ) : profileData.userID ? (
                    <div className='row'>
                        <div className='col col-12 col-md-6'>
                            <Card
                                cover={<img src={profileData?.userCoverURL === null ? ImageBaseURL + "mycover.jpg" : ImageBaseURL + profileData?.userCoverURL} alt='user-cover' />}
                                className='personal-info-card'
                                style={{ height: 'auto' }}>
                                <div className='d-flex align-items-center justify-content-between user-actions'>
                                    <Avatar src={profileData?.userPictureURL === null ? ImageBaseURL + "mypicture.jpg" : ImageBaseURL + profileData?.userPictureURL} size={85} />

                                    <Space style={{ margin: "0px!important" }} direction='horizontal'>
                                        <CopyToClipboard text={window.location.href}>
                                            <Tooltip title= { userLanguage ? "Share" :'Paylaş'}>
                                                <Button onClick={notify} shape='circle'>
                                                    <ShareAltOutlined style={{ margin: 0 }} />
                                                </Button>
                                            </Tooltip>
                                        </CopyToClipboard>
                                            <VCardGenerator company={profileData?.companytb} data={profileData} userLanguage={userLanguage} />
                                    </Space>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                    {isEditProfile && (
                                        <Button
                                            onClick={() => window.location.replace(`/vertical/edit-account/${userId}`)}
                                            style={{
                                                backgroundColor: '#e91e63',
                                                borderRadius: 100,
                                                color: '#fff',
                                                border: 'none',
                                                margin: 0
                                            }}>
                                                {
                                                    userLanguage ? "Edit Profile" : "Profili Düzenle" 
                                                }
                                             
                                        </Button>
                                    )}
                                </div>

                                <div className='d-flex align-items-center justify-content-between'>
                                    <h5 className='mb-0 mt-0 ml-0 mr-0'>
                                        {profileData.userFirstName} {profileData.userMiddleName} {profileData.userLastName}
                                    </h5>
                                </div>

                                <p style={{ color: '#8f8f90' }}>{profileData.userTitle} </p>
                            </Card>
                            
                            <Select onChange={(e) => {setUserLanguage(e === "EN")}} defaultValue={userLanguage ? "EN" : "TR"} style={{position: "absolute", top :25 , left:25,backgroundColor:"black",width:"70px",height:"30px"}}>
                                {languageList.map((language, index) => (
                                    <Option key={language} value={language}>
                                        {language}
                                    </Option>
                                ))}
                            </Select>
                            {isEditProfile && (
                                <div className='d-flex align-items-center justify-content-between flex-column mt-4 p-1'>
                                    <Switch
                                        defaultChecked={profileData.userVisiblePage}
                                        onChange={(event) => onChangeActive(profileData as IUser, event)}
                                    />
                                    <Typography.Paragraph style={{ color: 'primary' }}>
                                    {
                                        userLanguage ? "My Profile Active/Deactive" : "Profilim Aktif / Pasif" 
                                    }
                                    </Typography.Paragraph>
                                </div>
                            )}
                            <Card title={ userLanguage ? "Contact information" : 'İletişim Bilgileri' }>
                            {profileData.userPersonalMobile && (
                                <div className='row align-items-center mb-3'>
                                    <div className='col col-auto'>
                                        <span
                                            className='icofont-ui-touch-phone'
                                            style={{ fontSize: 30, color: '#8f8f90' }}
                                        />
                                    </div>
                                    <div className='col'>
                                        {
                                            //<div>Cep Telefonu</div>
                                        }
                                        <a href={'tel:' + profileData.userPersonalMobile}>
                                            {profileData.userPersonalMobile}
                                        </a>
                                    </div>
                                </div>
                            )}

                                {profileData.userPhoneMobile2 && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-ui-touch-phone'
                                                style={{ fontSize: 30, color: '#8f8f90' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                // <div>İkinci Telefon Numarası</div>
                                            }
                                            <a href={'tel:' + profileData.userPhoneMobile2}>
                                                {profileData.userPhoneMobile2}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {profileData.userPhoneHome && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-ui-touch-phone'
                                                style={{ fontSize: 30, color: '#8f8f90' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                // <div>İkinci Telefon Numarası</div>
                                            }
                                            <a href={'tel:' + profileData.userPhoneHome}>
                                                {profileData.userPhoneHome}
                                            </a>
                                        </div>
                                    </div>
                                )}


                                {profileData.userWorkMobile && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-telephone'
                                                style={{ fontSize: 30, color: '#8f8f90' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>İş Telefonu</div>
                                            }

                                            <a href={'tel:' + profileData.userWorkMobile}>{profileData.userWorkMobile}</a>
                                        </div>
                                    </div>
                                )}

                                {profileData.userPhoneWorkExtension && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-telephone'
                                                style={{ fontSize: 30, color: '#8f8f90' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>İş Telefonu Dahili</div>
                                            }

                                            <a href={'tel:' + profileData.userPhoneWorkExtension}>
                                                {profileData.userPhoneWorkExtension}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                {profileData.userEmailMain && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span className='icofont-email' style={{ fontSize: 25, color: '#8f8f90' }} />
                                        </div>
                                        <div className='col'>
                                            {/*<div>Mail Adresi</div>*/}
                                            <span>
                                                <a href={'mailto:' + profileData.userEmailMain}>
                                                    {profileData.userEmailMain}
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                )}




                                {profileData.userEmailAdditional && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span className='icofont-email' style={{ fontSize: 25, color: '#8f8f90' }} />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>İkincil Mail Adresi</div>
                                            }

                                            <a href={'mailto:' + profileData.userEmailAdditional}>
                                                {' '}
                                                {profileData.userEmailAdditional}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                {profileData.userIBAN && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span className='icofont-bank' style={{ fontSize: 30, color: '#8f8f90' }} />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>IBAN</div>
                                            }

                                            <CopyToClipboard text={profileData.userIBAN}>
                                                <a onClickCapture={notify} style={{ fontSize: 12 }}>
                                                    {profileData.userIBAN}
                                                    <span
                                                        className='icofont-copy'
                                                        style={{ marginLeft: 6, fontSize: 20, color: '#8f8f90' }}></span>
                                                </a>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                )}

                                {profileData.userAddress && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-location-pin'
                                                style={{ fontSize: 30, color: '#8f8f90' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>Adres</div>
                                            }

                                            <a href={'http://maps.google.com/?q=' + profileData.userAddress}>
                                                {profileData.userAddress}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                {profileData.userBirthdate && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-birthday-cake'
                                                style={{ fontSize: 30, color: '#8f8f90' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>Doğum Tarihi</div>
                                            }

                                            {getBirthday()}
                                        </div>
                                    </div>
                                )}
                            </Card>

                            <Card title={ userLanguage ? "Websites and Social channels" : 'Web siteleri ve Sosyal kanallar'  } className='mb-md-0'>
                                {profileData.userInstagramURL && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-instagram'
                                                style={{ fontSize: 30, color: '#E1306C' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>Instagram</div>
                                            }

                                            <a
                                                onClick={setSocialLink}
                                                href={`http://${profileData.userInstagramURL}`}
                                                target='_blank'
                                                rel='noopener noreferrer'>
                                                {profileData.userInstagramURL}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                {profileData.userTwitterURL && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-twitter'
                                                style={{ fontSize: 30, color: '#1da1f2' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>Twitter</div>
                                            }

                                            <a
                                                onClick={setSocialLink}
                                                href={`http://${profileData.userTwitterURL}`}
                                                target='_blank'
                                                rel='noopener noreferrer'>
                                                {profileData.userTwitterURL}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                {profileData.userLinkedinURL && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-linkedin'
                                                style={{ fontSize: 30, color: '#0073b1' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>LinkedIn</div>
                                            }

                                            <a
                                                onClick={setSocialLink}
                                                href={`http://${profileData.userLinkedinURL}`}
                                                target='_blank'
                                                rel='noopener noreferrer'>
                                                {profileData.userLinkedinURL}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {profileData.userFacebookURL && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-facebook'
                                                style={{ fontSize: 30, color: '#4267B2' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                // <div>Facebook</div>
                                            }

                                            <a
                                                onClick={setSocialLink}
                                                href={`http://${profileData.userFacebookURL}`}
                                                target='_blank'
                                                rel='noopener noreferrer'>
                                                {profileData.userFacebookURL}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {profileData.userGithub && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span className='icofont-github' style={{ fontSize: 30, color: '#ff0000' }} />
                                        </div>
                                        <div className='col'>
                                            {
                                                // <div>Github</div>
                                            }

                                            <a
                                                onClick={setSocialLink}
                                                href={`http://${profileData.userGithub}`}
                                                target='_blank'
                                                rel='noopener noreferrer'>
                                                {profileData.userGithub}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                {profileData.userYoutube && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span
                                                className='icofont-youtube'
                                                style={{ fontSize: 30, color: '#ff0000' }}
                                            />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>YouTube</div>
                                            }

                                            <a
                                                onClick={setSocialLink}
                                                href={`http://${profileData.userYoutube}`}
                                                target='_blank'
                                                rel='noopener noreferrer'>
                                                {profileData.userYoutube}
                                            </a>
                                        </div>
                                    </div>
                                )}

                                {profileData.userPersonalMobile && (
                                  <div className='row align-items-center mb-3'>
                                    <div className='col col-auto'>
                                      <span
                                        className='icofont-whatsapp'
                                        style={{ fontSize: 30, color: '#65d358' }}
                                      />
                                    </div>
                                    <div className='col'>
                                      {
                                        // <div>Whatsapp</div>
                                      }

                                      <a href={'https://wa.me/' + profileData.userPersonalMobile}>
                                        {profileData.userPersonalMobile}
                                      </a>
                                    </div>
                                  </div>
                                )}

                                {profileData.userSocialMediaURL && (
                                    <div className='row align-items-center mb-3'>
                                        <div className='col col-auto'>
                                            <span className='icofont-link' style={{ fontSize: 30, color: '#8f8f90' }} />
                                        </div>
                                        <div className='col'>
                                            {
                                                //<div>Özel Bağlantı</div>
                                            }

                                            <a href={profileData?.userSocialMediaURL}>
                                                {profileData?.userSocialMediaURL}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '40px', }}>
                                    <QRCode value={window.location.href} />
                                </div>

                                


                            </Card>
                        </div>

                        {profileData?.companytb?.companyName && (
                            <div className='col col-12 col-md-6'>
                                <Card
                                    id="companyCard"
                                    title= { userLanguage ? "Company Information" : 'Şirket Bilgileri' }
                                    className='mb-md-0'
                                // style={{ borderRadius: '0 0 100px 100px!important'}}
                                >
                                    <Card
                                        /*
                                          actions={[
                                            <Card.Meta
                                              style={{ borderRadius: '0 0 100px 100px' }}
                                              avatar={
                                                companyData?.companyFooterURL === null ?  <></>:
                                                <img
                                                  style={{ marginBottom: '-12px' }}
                                                  src={ImageBaseURL+companyData?.companyFooterURL}
                                                />
                                              }
                                            />,<p></p>,
                                            <Card.Meta
                                              title={companyData.companyFooterText}
                                            />
                                          ]}*/
                                        bordered={false}
                                        cover={
                                            <img src={profileData?.companytb?.companyCoverURL === null ? ImageBaseURL + "mycover.jpg" : ImageBaseURL + profileData?.companytb?.companyCoverURL} alt='company-cover' />
                                        }
                                        className='personal-info-card'
                                        style={{ height: 'auto' }}>
                                        {
                                            /*
                                            
                                          <div className='d-flex align-items-center justify-content-between user-actions'>
                                            <Avatar
                                              src={companyData?.companyLogoURL === null ? ImageBaseURL + "mypicture.jpg" : ImageBaseURL + companyData?.companyLogoURL}
                                              size={85}
                                              style={{ marginBottom: 10 }}
                                            />
                                          </div>
                                              */
                                        }

                                        {profileData?.companytb?.companyName && (
                                            <div className='row align-items-center mb-3'>
                                                <div className='col col-auto'>
                                                    <span
                                                        className='icofont-bag-alt'
                                                        style={{ fontSize: 30, color: '#6666cc' }}
                                                    />
                                                </div>
                                                <div className='col'>
                                                    {
                                                        //<div>Şirket Adı</div>
                                                    }

                                                    {profileData?.companytb?.companyName}
                                                </div>
                                            </div>
                                        )}

                                        {profileData?.companytb?.companyPhone1 && (
                                            <div className='row align-items-center mb-3'>
                                                <div className='col col-auto'>
                                                    <span
                                                        className='icofont-ui-dial-phone'
                                                        style={{ fontSize: 30, color: '#6666cc' }}
                                                    />
                                                </div>
                                                <div className='col'>
                                                    {
                                                        //<div>Şirket Telefonu</div>
                                                    }

                                                    <a href={'tel:' + profileData?.companytb?.companyPhone1}>
                                                        {profileData?.companytb?.companyPhone1}
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                        {profileData?.companytb?.companyPhone2 && (
                                            <div className='row align-items-center mb-3'>
                                                <div className='col col-auto'>
                                                    <span
                                                        className='icofont-ui-dial-phone'
                                                        style={{ fontSize: 30, color: '#6666cc' }}
                                                    />
                                                </div>
                                                <div className='col'>
                                                    {
                                                        // <div>Şirket Ek Telefonu</div>
                                                    }

                                                    <a href={'tel:' + profileData?.companytb?.companyPhone2}>
                                                        {profileData?.companytb?.companyPhone2}
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                        {profileData?.companytb?.companyEmailMain && (
                                            <div className='row align-items-center mb-3'>
                                                <div className='col col-auto'>
                                                    <span
                                                        className='icofont-email'
                                                        style={{ fontSize: 30, color: '#6666cc' }}
                                                    />
                                                </div>
                                                <div className='col'>
                                                    {
                                                        //<div>E-posta</div>
                                                    }

                                                    <a href={'mailto:' + profileData?.companytb?.companyEmailMain}>
                                                        {profileData?.companytb?.companyEmailMain}
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                        {profileData?.companytb?.companyHomepageURL && (
                                            <div className='row align-items-center mb-3'>
                                                <div className='col col-auto'>
                                                    <span
                                                        className='icofont-web'
                                                        style={{ fontSize: 30, color: '#6666cc' }}
                                                    />
                                                </div>
                                                <div className='col'>
                                                    {
                                                        //<div>Web Sitesi</div>
                                                    }

                                                    <a href={'https://' +profileData?.companytb?.companyHomepageURL}>
                                                        {profileData?.companytb?.companyHomepageURL}
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                        {profileData?.companytb?.companyAddress && (
                                            <div className='row align-items-center mb-3'>
                                                <div className='col col-auto'>
                                                    <span
                                                        className='icofont-location-pin'
                                                        style={{ fontSize: 30, color: '#6666cc' }}
                                                    />
                                                </div>
                                                <div className='col'>
                                                    {
                                                        //<div>Adres</div>
                                                    }

                                                    <a href={'http://maps.google.com/?q=' + profileData?.companytb?.companyAddress}>
                                                        {profileData?.companytb?.companyAddress}
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                        {profileData?.companytb?.companyLinkedinURL && (
                                            <div className='row align-items-center mb-3'>
                                                <div className='col col-auto'>
                                                    <span
                                                        className='icofont-linkedin'
                                                        style={{ fontSize: 30, color: '#0073b1' }}
                                                    />
                                                </div>
                                                <div className='col'>
                                                    {
                                                        //<div>LinkedIn</div>
                                                    }

                                                    <a href={'https://' +profileData?.companytb?.companyLinkedinURL}>
                                                        {profileData?.companytb?.companyLinkedinURL}
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                        {profileData?.companytb?.companyInstagramURL && (
                                            <div className='row align-items-center mb-3'>
                                                <div className='col col-auto'>
                                                    <span
                                                        className='icofont-instagram'
                                                        style={{ fontSize: 30, color: '#E1306C' }}
                                                    />
                                                </div>
                                                <div className='col'>
                                                    {
                                                        //<div>Instagram</div>
                                                    }

                                                    <a href={'https://' +profileData?.companytb?.companyInstagramURL}>
                                                        {profileData?.companytb?.companyInstagramURL}
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                        {profileData?.companytb?.companySocialMediaURL && (
                                            <div className='row align-items-center mb-3'>
                                                <div className='col col-auto'>
                                                    <span
                                                        className='icofont-link'
                                                        style={{ fontSize: 30, color: '#8f8f90' }}
                                                    />
                                                </div>
                                                <div className='col'>
                                                    {
                                                        //<div>Özel Bağlantı</div>
                                                    }

                                                    <a href={profileData?.companytb?.companySocialMediaURL}>
                                                        {profileData?.companytb?.companySocialMediaURL}
                                                    </a>
                                                </div>
                                            </div>
                                        )}

                                        {profileData?.companytb?.companyFooterList && (
                                            <div className="footerCenter">
                                                <div className="row mt-3">
                                                    {
                                                        profileData?.companytb?.companyFooterList?.map((row, index) =>
                                                        (
                                                            row != null ?
                                                                <Card
                                                                    cover={<img src={ImageBaseURL + row.imageId} alt='user-cover' style={{height:"110px"}} />}
                                                                    className='personal-info-card'
                                                                    bodyStyle={{ padding: 0 }}
                                                                    style={{ width: row.size === 3 ? '100%' :(row.size  === 2 ? '65%' :`30%`), margin: '5px', height:"150px!important" }}>
                                                                </Card>
                                                                :
                                                                <></>
                                                        )
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                        }
                                        {
                                            userLanguage ? 
                                            profileData?.companytb?.companyFooterTextEN && (
                                                    <div>{profileData?.companytb?.companyFooterTextEN}</div>)
                                                    :
                                                    profileData?.companytb?.companyFooterTextTR && (
                                                    <div>{profileData?.companytb?.companyFooterTextTR}</div>)

                                        }
                                    </Card>
                                </Card>
                            </div>
                        )}
                    </div>
                ) : (
                    <Suspense fallback={() => <div>YÜKLENİYOR</div>}>
                        <Result
                            status='404'
                            title='404'
                            subTitle='Ziyaret ettiğiniz sayfa mevcut değil.'
                            extra={
                                <Button href='/' type='primary'>
                                    Ana Sayfa
                                </Button>
                            }
                        />
                    </Suspense>
                )}
        </>
    );
};

export default PageViewProfile;
