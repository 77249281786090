export const stringToPercent = (value : string) =>{
    let values: string[] = value.split("/");
    let percent:number = (parseInt(values[0])/parseInt(values[1]))*100;
    return Math.trunc(percent);
}
export const RoxcardAuthName = "RoxCardAuth";

//EREN PERAKENDE
//export const API_URL2: string = 'https://digitalcardapi.azurewebsites.net';

//LOCAL
//export const API_URL2: string = "https://localhost:7011"; 

/// Roxcard Hosting
export const API_URL2: string = 'https://app.roxcard.com.tr/api';

export const ImageBaseURL: string = API_URL2+"/image/";

export const API_REQUEST_TIMEOUT: number = 8000;
export const TITLE_FONT_SIZE = 60;
export const VALUE_FONT_SIZE = 120;
export const BG_WIDGET_COLOR = "#3640f9";
export const BG_TABLE_WIDGET_COLOR = "#E7363D";
export const BG_GRAPHIC_WIDGET_COLOR = "#E7363D";
export const TEXT_COLOR = "#FFF";
