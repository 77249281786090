import axios from 'axios';

import {API_URL2, API_REQUEST_TIMEOUT} from "./Constants";
import { StateLoader } from '../redux/StateLoader';
import { IAuthentication } from '../interfaces/authentication';

export interface IResponse {
    data: any,
    status: number,
    errorText: string,
    dateTime: string
}

axios.defaults.baseURL = API_URL2;
axios.defaults.timeout = API_REQUEST_TIMEOUT;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
//axios.defaults.headers = { 'Content-Type': 'application/json; charset=utf-8' };
//axios.defaults.headers = { 'Content-Security-Policy': 'upgrade-insecure-requests'};

// Status 200 ise result.data
// Status 200 disinda herhangi x ise response.status ve response.statusText
// Network vb hatalar ise error.response error.message (response undefined)

axios.interceptors.response.use(
    (response) => {
        // console.dir(response);
        return response;
    },
    (error) => {
        // console.dir(error);
        let response: IResponse = {} as IResponse;
        response.dateTime = error.response.headers['server-date'];
        if (error.response === typeof undefined) {
            // API'in cevap donemedigi durumlarda yasanan hata
            response.data = null;
            response.status = 0;
            response.errorText = error.message;
        }
        else if (error.response.status === 401) 
        {

            loader.removeAuthState();
        }
        else {
            response.data = null;
            response.status = error.response.status;
            if (error.response.data == null)
                response.errorText = error.response.statusText;
            //let t = JSON.parse(error.response.data);
            //response.errorText = t.message;
            response.errorText = error.response.data.message;
        }
        return Promise.reject(response);
    }
);

const loader = new StateLoader();
const authInfo : IAuthentication = loader.getAuthState();
{console.log(authInfo)}
class HTTPService {
    static API_URL: any = API_URL2;


    static LOGIN = (URL: string, query: object = {}) => {
        query = query || {};

        let response: IResponse = {} as IResponse;
        return axios
            .get(URL,{
                params: query,
            })
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = result.data;
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static GET = (URL: string, query: object = {}) => {
        
        query = query || {};
        let response: IResponse = {} as IResponse;
        console.log(`get: ${authInfo}, zaman: ${new Date().toISOString()}`);
        return axios
            .get(URL,{
                params: query,
                headers: { 
                    "UserID": authInfo?.userApiKey ?? "",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }})
            .then((result) => {
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = result.data;
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }

    static POST = (URL: string, data: Object, query? :Object) => {
        let response: IResponse = {} as IResponse;
        console.log(`post: ${authInfo}, zaman: ${new Date().toISOString()}`);
        return axios
            .post(URL, data,{
                params: query,
                headers: { 
                    "UserID": authInfo?.userApiKey ?? "",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }})
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }
    static POSTForm = (URL: string, data: FormData) => {
        let response: IResponse = {} as IResponse;
        console.log(`postForm: ${authInfo}, zaman: ${new Date().toISOString()}`);
        return axios
            .post(URL, data,{
                headers: { 
                    "UserID": authInfo?.userApiKey ?? "",
                }})
            .then((result) => {
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }
    static PUT = (URL: string, data: Object) => {
        let response: IResponse = {} as IResponse;
        console.log(`put: ${authInfo}, zaman: ${new Date().toISOString()}`);
        return axios
            .put(URL, data, {headers: { "UserID": authInfo?.userApiKey ?? "" }})
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }
    static PUTForm = (URL: string, data: FormData) => {
        let response: IResponse = {} as IResponse;
        console.log(`putForm: ${authInfo}, zaman: ${new Date().toISOString()}`);
        return axios
            .put(URL, data, {headers: { "UserID": authInfo?.userApiKey?? ""  }})
            .then((result) => {
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }
    static DELETE = (URL: string) => {
        let response: IResponse = {} as IResponse;
        console.log(`delete: ${authInfo}, zaman: ${new Date().toISOString()}`);
        return axios
            .delete(URL, { headers: { "UserID": authInfo?.userApiKey ?? ""} })
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }
    static DELETEForm = (URL: string, data: FormData) => {        
        let response: IResponse = {} as IResponse;
        console.log(`deleteForm: ${authInfo}, zaman: ${new Date().toISOString()}`);
        return axios
            .delete(
                URL,{
                data: data,  
                headers: {
                "UserID": authInfo?.userApiKey ?? "",
            }})
            .then((result) => {
                //console.dir(result);
                response.status = result.status;
                response.errorText = result.statusText;
                response.data = JSON.stringify(result.data);
                response.dateTime = result.headers['server-date'];
                return response;
            })
            .catch((response) => {
                return response;
            });
    }
}

export default HTTPService;