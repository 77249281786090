import { RoxcardAuthName } from "../utils/Constants";
import Cookies from 'js-cookie';

export class StateLoader {

    getAuthState() {
        try{
            let serializedState = Cookies.get(RoxcardAuthName);
            if(serializedState != null)
            {
                return JSON.parse(serializedState);
            }
            else
            {
                return null;
            }
        }
        catch(ex)
        {
            return null;
        }



    }

    setAuthState(state) {
        try {
            let serializedState = JSON.stringify(state);
            Cookies.set(RoxcardAuthName, serializedState);
            window.location.replace('/vertical/dashboard/');
        }
        catch (err)
         {
            console.log(err);
        }
    }

    removeAuthState() {
        try{
            console.log("remove");     
            Cookies.remove(RoxcardAuthName);
            window.location.replace('/public/sign-in/');
            
        }
        catch(ex){
            console.log(ex);
        }
    };
}