import React, { useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import { Button, Card, Input, Space, Switch, Table, Tag, Row, Col, Popconfirm, Form, Checkbox } from 'antd';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import HTTPService from '../../../utils/makerequest';
import { IUser } from '../../../interfaces/user';
import { toast } from 'react-hot-toast';
import '../UserList/UserList.css';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';

interface IUserData{
  rows: IUser[],
  count: number
}
const UserRolesforColmn = {
  2: 'Yönetici',
  1: 'Editör',
  0: 'Üye',
};

const UserList: React.FunctionComponent<IPageProps> = (props) => {
  const [userData, setUserData] = useState<IUserData>();
  const { onSetPage } = props;
  const pageData: IPageData = {
    title: 'View profile',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'User Pages ',
        route: 'dashboard',
      },
      {
        title: 'View Profile',
      },
    ],
  };
  
  useEffect(() => onSetPage(pageData), []);
  useEffect(() => { fetchUserInfo({page : 1,pageSize:10}) }, []);

  const fetchUserInfo = (e?) => {
    console.log(e)
    HTTPService.POST('/user/allUser',e).then((response) => {
      if (!response.data) {
        return;
      }
      let tmp :IUserData= JSON.parse(response.data);
      
      setUserData(tmp);
    })
    .catch((err) => {
      console.error(err);
      toast.error("Bir Hata Oluştu...")
    });
  }

  const columnsDatagrid : IAltisDataGridColumns[] = [
    {
      title: 'Şirket',
      key: 'companyName',
      placeHolder: "Şirket",
      inputType:"input"
    },
    {
      title: 'Adı',
      key: 'userFirstName',
      placeHolder: "Adı",
      inputType:"input"
    },
    {
      title: 'Soyadı',
      key: 'userLastName',
      placeHolder: "Soyadı",
      inputType:"input"
    },
    {
      title: 'Yetki',
      key: 'userRole',
      placeHolder: "yetki",
      inputType:"dropdown",
      dropdownItem: [{id: 0, name:"User"},{id: 1, name:"Editor"} ,{id: 2, name:"Admin"} ],
      render: ( value, record ) => {
        return <span>{UserRolesforColmn[value]}</span>;
      },
    },
    {
      title: 'E-Posta',
      key: 'userEmailMain',
      placeHolder: "E-Posta",
      inputType:"input",
    },
    {
      title: 'Parola Sıfırla',
      key: 'password',
      placeHolder: "parola",
      inputType:"operations",
      render: ( index,record: IUser) => (
        <div style={{ textAlign: 'center' }}>
          <Button key={index} type='primary' htmlType='submit' style={{ height: '40px', width: '80px' }} onClick={() => { onPasswordReset(record) }}>
            Sıfırla
          </Button>
        </div>
      ),
    },
    {
      title: 'Kartvizit',
      key: 'userIsCardPrinted',
      placeHolder: "Sadece Basılan",
      inputType:"switch",
      render: (userIsCardPrinted, record) => (
        <div style={{ textAlign: 'center' }}>
        <Form fields={[ { name: ['userIsCardPrinted'], value: userIsCardPrinted}]}  >
          <Form.Item name="userIsCardPrinted" valuePropName="checked">
          <Switch onChange={(event) => onChangeCardPrinted(record, event)} />
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      title: 'Aktif',
      key: 'userActive',
      placeHolder: "Sadece Aktif Olan",
      inputType:"switch",
      render: (userActive, record) => (
        <div style={{ textAlign: 'center' }}>
          <Form fields={[ { name: ['userTitle'], value: userActive}]}  >
            <Form.Item name="userTitle" valuePropName="checked">
              <Switch  onChange={(event)=> onChangeActive(record,event)}/>
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
        title: 'İşlemler',
        key: 'inspect',
        placeHolder: "userActive",
        inputType:"operations",
        render: (order, record: IUser) => (
            <div style={{ textAlign: 'center' }}>
                <Space>
                    <Button
                        shape='circle'
                        size='small'
                        icon={<EditOutlined />}
                        onClick={() => {
                            openInNewPageEdit(record);
                        }}
                    />
                    <Button
                        shape='circle'
                        size='small'
                        icon={<EyeOutlined />}
                        onClick={() => {
                            openInNewPage(record);
                        }}
                    />
                    <Popconfirm
                        title="Bu kullanıcıyı silmek istediğinizden emin misiniz?"
                        onConfirm={() => {
                            handleDelete(record.userID);
                        }}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            shape='circle'
                            size='small'
                            icon={<DeleteOutlined />}
                            onClick={(e) => { e.stopPropagation(); }}
                        />
                    </Popconfirm>
                </Space>
        </div>
      ),
    },
  ];

  const onChangeCardPrinted = (record: IUser, event) => {
   HTTPService.PUT(`/user/isPrinted?id=${record.userID}&isPrinted=${event.toString()}`, event)
      .then((res) => {
        if (res.statusText === 'OK') {
          toast.success('Updated.');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };

  const onChangeActive = (record: IUser, event) => {
    HTTPService.PUT(`/user/Deactive?id=${record.userID}&activate=${event.toString()}`, event)
      .then((res) => {
        if (res.statusText === 'OK') {
          toast.success('Updated.');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };

  const onPasswordReset = (record: IUser) => {
    console.log(record)
    HTTPService.LOGIN(`/Login/passReset1?email=${record.userEmailMain}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success('Parola Sıfırlama Bağlantısı Gönderildi');
        } else {
          toast.error('Parola Sıfırlama Bağlantısı Gönderilirken Bir Hata Oluştu!');
        }
      })
      .catch((response) => {
        console.error(response)
        toast.error("Bir Hata Oluştu.")
        return;
      });
  };

  const openInNewPage = (record: IUser) => {
    window.open(`/user/${record.userID}`, '_blank');
  };
  
  const openInNewPageEdit = (record: IUser) => {
    window.open(`/vertical/edit-account/${record.userID}`, '_blank');
  };

  const handleDelete = async (userId) => {

    try {
        const response = await HTTPService.DELETE(`/user?id=${userId}`);

        if (response.status === 200) {
            toast.success('Kullanıcı başarıyla silindi.');
        } else {
            toast.error('Kullanıcı silinirken bir hata oluştu.');
        }
    } catch (error) {
        toast.error('Kullanıcı silinirken bir hata oluştu.');
    }
    window.location.reload();
};

  return (
    <>
      <Card bodyStyle={{ padding: 0 }} title='Kullanıcı Listesi'>
        <Row gutter={16} justify='center' style={{ marginBottom: 16, marginTop: 16, padding: 10, display: 'flex', alignItems: 'center'}}>
          <Col span={17}>
            <Button shape='round' style={{ height: 49 }} icon={<UserAddOutlined />} size='middle' onClick={() => window.location.replace('/vertical/quick-add')}>
              Tek bir kullanıcı ekle
            </Button>
          </Col>
          <Col span={7}>
            <Button style={{ height: 49 }} shape='round' icon={<DownloadOutlined />} size='middle' onClick={() => window.location.replace('/vertical/import-users')}>
              Kullanıcıyı Excel ile içe aktar
            </Button>
          </Col>
        </Row>

          <AltisDataGrid data={userData?.rows} total={userData?.count} columns={columnsDatagrid} onChange={fetchUserInfo}/>
      </Card>
    </>
  );
};

export default UserList;
