import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  Form,
  Input,
} from 'antd';

import { IPageData, IPageProps } from '../../../interfaces/page-data';
import {IUserUpdate } from '../../../interfaces/user';
import { toast } from 'react-hot-toast';
import { ICompany } from '../../../interfaces/company';
import userCover from '../../../assets/content/user-profile.jpg';
import HTTPService from '../../../utils/makerequest';


const CompanyForm: React.FC<any> = ({ props, data }) => {
  const post = (e) => {
    let company: ICompany = {
      companyName: e.companyName,
      companyAddress: e.companyAddress,
      companyPhone1: e.companyPhone1,
      companyPhone2: e.companyPhone2,
      companyHomepageURL: e.companyHomepageURL,
      companyEmailMain: e.companyEmailMain,
      companyLinkedinURL: e.companyLinkedinURL,
      companyInstagramURL: e.companyInstagramURL,
      companySocialMediaURL: e.companySocialMediaURL,
      companyEmail: e.companyEmail,
      companyFooterText: e.companyFooterText,
      companyCoverURL: userCover,
    } as ICompany;

    HTTPService.POST('/Company', company)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          // 2xx durum kodları (Başarı durumları)
          toast.success('Şirket başarıyla eklendi.');
          window.location.href = '/vertical/company-list';
        } else if (res.status >= 400 && res.status < 500) {
          // 4xx durum kodları (İstemci hataları)
          res
            .json()
            .then((data) => {
              toast.error(`Bir istemci hatası oluştu: ${data.message}`);
            })
            .catch(() => {
              toast.error('Bir istemci hatası oluştu.');
            });
        } else if (res.status >= 500 && res.status < 600) {
          // 5xx durum kodları (Sunucu hataları)
          res
            .json()
            .then((data) => {
              toast.error(`Bir sunucu hatası oluştu: ${data.message}`);
            })
            .catch(() => {
              toast.error('Bir sunucu hatası oluştu.');
            });
        } else {
          // Diğer durum kodları
          toast.error('Bir hata oluştu.');
        }
      })
      .catch((error) => {
        toast.error('Bir hata oluştu!', error);
      });
  };

  return (
    <Form layout='vertical' onFinish={post}>
      <Form.Item
        label='Şirket Adı'
        name='companyName'
        rules={[
          { required: true, message: 'Lütfen şirket adı giriniz' }
        
        ]}>
        <Input placeholder='Altis' />
      </Form.Item>

      <Form.Item
        label='E-posta Adresi'
        name='companyEmailMain'
        >
        <Input placeholder='name@company.com' />
      </Form.Item>
      <Form.Item
        label='İkincil E-posta Adresi'
        name='companyEmail'
        rules={[
          { required: false, message: 'Lütfen e-posta adresinizi girin.' },
          { type: 'email', message: 'Lütfen geçerli bir e-posta adresi girin.' },
        ]}>
        <Input placeholder='name@company.com' />
      </Form.Item>

      <Form.Item
        label='Telefon Numarası'
        name='companyPhone1'
        rules={[
          { required: true, message: 'Lütfen telefon numaranızı girin.' },
          { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
        ]}>
        <Input placeholder='+90XXXXXXXXXX' />
      </Form.Item>

      <Form.Item
        label='İkincil Telefon Numarası'
        name='companyPhone2'
        rules={[
          { required: false, message: 'Lütfen telefon numaranızı girin.' },
          { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
        ]}>
        <Input placeholder='+90XXXXXXXXXX' />
      </Form.Item>

      <Form.Item
        label='Adres'
        name='companyAddress'
        rules={[
          { required: true, message: 'Lütfen adres giriniz' },
          { type: 'string', min: 10, message: 'Lütfen adrese en az 10 karakter giriniz' },
        ]}>
        <Input.TextArea placeholder='71 Pilgrim Avenue Chevy Chase, MD 20815' />
      </Form.Item>

      <Form.Item
        label='Ana Sayfa'
        rules={[
          { required: true, message: 'Lütfen web adresinizi girin.' },
          {
            pattern:
              /^((https?:\/\/)?(www\.)?)\S+\.([a-z]{2,}|(com|net|org|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum))$/i,
            message: 'Lütfen geçerli bir web adresi giriniz.',
          },
        ]}
        name='companyHomepageURL'>
        <Input placeholder='https://www.website.com' />
      </Form.Item>

      <Form.Item label='LinkedIn' name='companyLinkedinURL'>
        <Input placeholder='www.linkedin.com/username' />
      </Form.Item>

      <Form.Item label='Instagram' name='companyInstagramURL'>
        <Input placeholder='www.instagram.com/username' />
      </Form.Item>

      <Form.Item label='Sosyal Medya URL' name='companySocialMediaURL'>
        <Input placeholder='www.example.com/' />
      </Form.Item>

      <Form.Item label='Footer Yazısı' name='companyFooterText'>
        <Input placeholder='Roxcard bir Altis teknoloji hizmetidir.' />
      </Form.Item>

      <div className='d-flex justify-content-between'>
        <Button danger ghost>
          İptal
        </Button>

        <Button type='primary' htmlType='submit'>
          Ekle
        </Button>
      </div>
    </Form>
  );
};

const PageImportCompanies: React.FC<IPageProps> = (props) => {
  const { onSetPage } = props;

  const [profileData] = useState<IUserUpdate>({});

  const pageData: IPageData = {
    title: 'Edit account',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'User Pages ',
        route: 'dashboard',
      },
      {
        title: 'Edit Account',
      },
    ],
  };

  useEffect(() => onSetPage(pageData), []);

  return (
    <div className='row justify-content-center'>
      <div className='col col-12 col-xl-8'>
        {/* <Card title='Şirket Logosu'>
          <div className='d-sm-flex align-items-center w-100 elem-list'>
            <Avatar src={avatar} size={100} className='mb-4' />

            <Button
              type='primary'
              htmlType='button'
              ghost
              onClick={() => fileInput.current.click()}>
              Şirket Logosunu Değiştir
              <UserOutlined />
            </Button>
            <input ref={fileInput} type='file' accept='image/*' hidden />
          </div>
        </Card> */}

        <Card title='Şirket Ekleme Ekranı'>
          <CompanyForm data={profileData} />
        </Card>
      </div>
    </div>
  );
};

export default PageImportCompanies;
