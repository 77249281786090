import React, { useEffect, useState } from 'react';
import uuid from 'uuid/v4';
import { Avatar,Button,Card,Popconfirm,Space,Switch} from 'antd';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import HTTPService from '../../../utils/makerequest';
import { toast } from 'react-hot-toast';
import { ICompany } from '../../../interfaces/company';
import { IAuthentication } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';
import AltisDataGrid, { IAltisDataGridColumns } from '../../../AltisComponent/AltisDataGrid';
import { ImageBaseURL } from '../../../utils/Constants';

interface ICompanyData{
  rows?: ICompany[],
  count?: number
}

const CompanyList: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [companyData, setCompanyData] = useState<ICompanyData>({});
  const pageData: IPageData = {
    title: 'View profile',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'User Pages ',
        route: 'dashboard',
      },
      {
        title: 'View Profile',
      },
    ],
  };

  useEffect(() => onSetPage(pageData), []);
  useEffect(() => fetchCompanyInfo(), []);

  const authInfo : IAuthentication = new StateLoader().getAuthState();

  const handleDelete = async (companyId) => {
      try 
      {
          const response = await HTTPService.DELETE(`Company?id=` + companyId);
          if (response.status === 200) 
          {
            toast.success('Şirket başarıyla silindi.');
          }
          else
          {
            toast.error('Şirket silinirken bir hata oluştu.');
          }
      } 
      catch (error)
      {
        toast.error('Şirket silinirken bir hata oluştu.');
      }
      window.location.reload();
  };


  const fetchCompanyInfo=(e?)=> {
    console.log(e);
    HTTPService.POST('/company/filter',e == null ? {} : e).then((response) => {

      if (response.data === null) {
        return;
      }
      
      const data: ICompanyData = JSON.parse( response.data);
      setCompanyData(data);
      console.log(companyData)
    });
  }

  const onChangeActive = (record: ICompany, event) => {
    const params = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        UserID: authInfo.userApiKey,
      },
      body: event,
    };
    HTTPService.PUT(`/Company/Deactive?id=${record.companyID}&activate=${event.toString()}`, params)
      .then((res) => {
        if (res.statusText === 'OK') {
          toast.success('Updated.');
        }
      })
      .catch((error) => {
        toast.error('There was an error!', error);
      });
  };

  const openInNewPageEdit = (record: ICompany) => {
    window.open(`/vertical/edit-company/${record.companyID}`, '_blank');
  };

  const altisDataGridColumns : IAltisDataGridColumns[]= [
    {
      title: '',
      key: 'companyLogoURL',
      placeHolder: "Aktif",
      inputType:"operations",
      render: (companyLogoURL, record) => (
        <Avatar className='mr-3' style={{ minWidth: 36, minHeight: 36 }} src={ImageBaseURL+ companyLogoURL} />
      ),
    },
    {
      title: 'Adı',
      key: 'companyName',
      placeHolder: "Adı",
      inputType:"input",
    },
    {
      title: 'Adres',
      key: 'companyAddress',
      placeHolder: "Adres",
      inputType:"input",
    },
    {
      title: 'Telefon',
      key: 'companyPhone1',
      placeHolder: "Telefon",
      inputType:"input",
    },
    {
      title: 'E-posta',
      key: 'companyEmailMain',
      placeHolder: "E-posta",
      inputType:"input",
    },
    {
      title: 'Aktif',
      key: 'companyActive',
      placeHolder: "Aktif",
      inputType:"operations",
      render: (companyActive, record) => (
        <div style={{ textAlign: 'center' }}>
          <Switch
            defaultChecked={companyActive}
            onChange={(event) => onChangeActive(record, event)}
          />
        </div>
      ),
    },
    {
      title: 'İşlemler',
      key: 'operations',
      placeHolder: "İşlemler",
      inputType:"operations",
      render: (order, record: ICompany) => (
          <div style={{ textAlign: 'center' }}>
              <Space>
                  <Button
                      shape='circle'
                      size='small'
                      icon={<EditOutlined />}
                      onClick={() => {
                          openInNewPageEdit(record);
                      }}
                  />
                  <Popconfirm
                      title="Bu şirketi silmek istediğinizden emin misiniz?"
                      onConfirm={() => {
                          handleDelete(record.companyID);
                      }}
                      okText="Evet"
                      cancelText="Hayır"
                  >
                      <Button
                          shape='circle'
                          size='small'
                          icon={<DeleteOutlined />}
                          onClick={(e) => {
                              // Tıklanmışsa, varsayılan tıklama davranışını engellemek için:
                              e.stopPropagation();
                          }}
                      />
                  </Popconfirm>

              </Space>

          </div>
        ),
    }
  ];

  
  return (
    <>
      <Card
        bodyStyle={{ padding: 0 }}
        title={
          <div className='card-header mb-4'>
            <div className='title-box'>
              <h5 className='title'>Şirket Listesi</h5>
            </div>

            <div className='actions d-flex'>
              <Button
                shape='round'
                icon={<DownloadOutlined />}
                size='middle'
                onClick={() => window.location.replace('/vertical/import-companies')}>
                Yeni Bir Şirket Ekle
              </Button>
            </div>
          </div>
        }>
        {companyData && (
            <AltisDataGrid data={companyData.rows} total={companyData.count} columns={altisDataGridColumns} onChange={fetchCompanyInfo}/>
        )}
      </Card>
    </>
  );
};

export default CompanyList;