import React, { FormEvent } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import {LockOutlined, MailOutlined, SolutionOutlined, UserOutlined} from "@ant-design/icons";

const LoginForm: React.FC<any> = props => {

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 }
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: `', values);
      }
    });
  };

  return (
    <>
      <Form onFinish={handleSubmit} className='login-form' {...formItemLayout} labelAlign='left'>
        <Form.Item label='First name' name='firstName' rules={ [{ required: true, message: <span className='error-message'>Please enter your first name</span> }]}>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='First name'
            />
        </Form.Item>
        <Form.Item label='Last name' name='lastName' rules={ [{ required: true, message: <span className='error-message'>Please enter your last name</span> }]}>
            <Input
              prefix={<SolutionOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Username'
            />
        </Form.Item>
        <Form.Item label='Email' name='email'
        rules={[{ required: true, message: <span className='error-message'>Please input your email</span> }]}>
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Username'
            />
        </Form.Item>
        <Form.Item label='Password' name='password' rules={ [{ required: true, message: <span className='error-message'>Please input your password</span> }]}>
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type='password'
              placeholder='Password'
            />
        </Form.Item>
        <Form.Item wrapperCol={{ xs: 24 }}>
          <Button htmlType='submit' className='login-form-button'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

//const WrappedLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

export default LoginForm;
