import React, { useState } from 'react';
import { Button, Form, Input, Space, Alert } from 'antd';

import { MailOutlined } from '@ant-design/icons';
import { toast } from 'react-hot-toast';
import HTTPService from '../../../utils/makerequest';
import { ArrowLeftOutlined } from '@ant-design/icons';

const PageResetPassword = () => {
  const [visibleErr, setVisible] = useState(false);
  const [visibleSuccess, setVisibleSuccess] = useState(false);

  function handleReturnBack() {
    window.history.back();
  }

  const sendPassword = (e) => {
    toast.loading('Loading...');
    HTTPService.LOGIN(`/Login/passReset1?email=${e.userEmailMain}`)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('emailCode', e.emailCode);
          setVisibleSuccess(true);
          toast.error('E-posta adresinize bir bağlantı gönderdik');
          setTimeout(function () {
            window.location.replace('/public/sign-in');
          }, 2000);
        } else {
          toast.error('Bu E-posta adresi bulunmuyor');
          setVisible(true);
        }
      })
      .catch((response) => {
        setVisible(true);
        return;
      });
  };

  const handleClose = () => {
    setVisible(false);
  };
  const handleCloseSuccess = () => {
    setVisibleSuccess(false);
  };

  return (
    <>
      <Button onClick={handleReturnBack} icon={<ArrowLeftOutlined />} style={{bottom: 20}} />
      <div className='title-block d-flex flex-column mb-4 p-0'>
        <h5 className='form-title mb-4'>Şifrenizi Sıfırlayın</h5>
        <span className='desc'>
          Kullanıcı adınızı veya e-posta adresinizi girin, şifrenizi sıfırlamak için bir bağlantı
          göndereceğiz.
        </span>
      </div>
      {visibleErr && (
        <Space direction='vertical' style={{ width: '100%', marginBottom: '20px' }}>
          <Alert
            message='Bu e-posta adresi bulunmuyor!'
            type='error'
            closable={true}
            afterClose={handleClose}
          />
        </Space>
      )}
      {visibleSuccess && (
        <Space direction='vertical' style={{ width: '100%', marginBottom: '20px' }}>
          <Alert
            message='E-posta adresinize bir bağlantı gönderdik'
            type='success'
            closable={true}
            afterClose={handleCloseSuccess}
          />
        </Space>
      )}
      <Form onFinish={sendPassword}>
        <Form.Item name='userEmailMain'>
          <div className='col'>
            <Input prefix={<MailOutlined />} bordered={false} placeholder='E-posta' />
          </div>
        </Form.Item>
        <div className='col-sm-4 col-12'>
          <Button style={{ width: '100%' }} type='primary' htmlType='submit'>
            Sıfırla
          </Button>
        </div>
      </Form>
    </>
  );
};

export default PageResetPassword;
