import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Tooltip } from 'antd';
import {QuestionCircleOutlined} from "@ant-design/icons";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 18,
      offset: 6
    }
  }
};

const RegistratioForm: React.FC<any> = props => {
  const [isDirty, setDirty] = useState<boolean>(false);

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  const handleConfirmBlur = e => {
    const { value } = e.target;
    setDirty(isDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && isDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  return (
    <Form {...formItemLayout} onFinish={handleSubmit}>
      <Form.Item label='E-mail' name='email' rules={ [
        {
            type: 'email',
            message: 'The input is not valid E-mail!'
        },
        {
            required: true,
            message: 'Please input your E-mail!'
        }
        ]}><Input />
      </Form.Item>
      <Form.Item label='Password' hasFeedback name='password' rules={[
          {
              required: true,
              message: 'Please input your password!'
          },
          {
              validator: validateToNextPassword
          }
      ]}>
        <Input.Password onBlur={handleConfirmBlur} />
      </Form.Item>
      <Form.Item label='Confirm Password' hasFeedback name='confirm' rules={[
          {
              required: true,
              message: 'Please confirm your password!'
          },
          {
              validator: compareToFirstPassword
          }
      ]}><Input.Password onBlur={handleConfirmBlur} />
      </Form.Item>
      <Form.Item
        label={
          <span>
            Nickname&nbsp;
            <Tooltip title='What do you want others to call you?'>
              <QuestionCircleOutlined/>
            </Tooltip>
          </span>
        }
      name='nickname'
      rules={[{ required: true, message: 'Please input your nickname!', whitespace: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label='Phone Number' name='phone' rules={ [{ required: true, message: 'Please input your phone number!' }]}>
        <Input style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item {...tailFormItemLayout} name='agreement' valuePropName='checked'>
          <Checkbox>
            I have read the <a href=''>Açık Rıza Metni</a>
          </Checkbox>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type='primary' htmlType='submit'>
          Kayıt Ol
        </Button>
      </Form.Item>
    </Form>
  );
};

//export default Form.create({ name: 'normal_registration' })(RegistratioForm);
export default RegistratioForm;
