import React, { useEffect, useState } from 'react';
import { Route, Redirect, matchPath } from 'react-router-dom';

import { IRoute, routes } from './routes';
import './App.scss';
import './assets/sass/styles.scss';
import { IAuthentication } from './interfaces/authentication';
import { StateLoader } from './redux/StateLoader';

const App: React.FC = () => {

  const [route, setRoute] = useState<string>(undefined);

  useEffect(() => {
    
    let layout = "/" + window.location.pathname.split('/')[1];
    console.log(layout);
    if (haveUser()) {
      console.log(`Layout: ${layout}, zaman: ${new Date().toISOString()}`);
      if (pathIsExist()) {
        if (layout === "/") {
          console.log(`sss: ${layout}, zaman: ${new Date().toISOString()}`);
          setRoute('/vertical/dashboard');
        }
        else {
          setRoute(window.location.pathname);
        }
      }
      else {
        if(layout !== "/user") {
          console.log(`eeew: ${layout}, zaman: ${new Date().toISOString()}`);
          setRoute('/vertical/dashboard');
        }
      }
    }
    else {
      console.log(`asas: ${layout}, zaman: ${new Date().toISOString()}`);
      if(layout !== "/user" && layout !== "/public") {
        setRoute('/public/sign-in');
      }

    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Path is exist for all routes
  const pathIsExist = () => {
    let result: boolean = false;
    let splittedURL = window.location.pathname.split('/');
    let currentLayout = '/' + splittedURL[1];
    let currentPath = '/' + splittedURL[2];
    let selectedRoute = [];
    if (currentLayout.length < 1 || currentLayout === '/') {
      return true;
    }
    routes.map((route) => {
      if (currentLayout.includes(route.path)) {
        selectedRoute = route.children;
        return null;
      }
    });
    if (selectedRoute.length > 0) {
      selectedRoute.map((item: IRoute) => {
        if (item.path.includes(currentPath)) {
          result = true;
          return null;
        }
      });
    } else {
      result = false;
    }

    return result;
  };

  const authUser :IAuthentication = new StateLoader().getAuthState();
  
  const haveUser = () => {
    return authUser !== null;
    
  };


  return (
    <div className='App'>
      {routes.map((route, i) => (
        <Route key={i} path={route.path} component={route.component} />
        //<Route key={i} path='/' component={() => <route.component/>}/>
      ))}
      {/*<Route exact path='/' render={() => <Redirect exact to={route}/>}/>*/}
      {route && <Redirect exact to={route} />}
    </div>
  );
};

export default App;
