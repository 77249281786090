import React, { useEffect, useRef, useState } from 'react';

import ReactEcharts from 'echarts-for-react';
import { Alert, Card,  Table,} from 'antd';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { IUserDashboardInformation } from '../../../interfaces/user';
import echarts from 'echarts';
import { IAuthentication } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';
import HTTPService from '../../../utils/makerequest';


const VisitorColumns = [
  {
    title: 'Şehir',
    dataIndex: 'city',
  },
  {
    title: 'Ziyaret Sayısı',
    dataIndex: 'total',
  },
];

const Dashboard: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage, getPageData } = props;
  const [dashboardData, setDashboardData] = useState<IUserDashboardInformation>(null);
  const [countPageData, setCountPageData] = useState([]);
  const [countPlatformData, setCountPlatformData] = useState([]);
  const [countVCardData, setCountVCardData] = useState([]);
  const [countSocialMediaData, setCountSocialMediaData] = useState([]);
  const [countCityData, setCountCityData] = useState([]);

  useEffect(() => {
    getDashboardData();
    onSetPage(pageData)
  }, []);

  const OptionVCard = {
    color: ['#c565e7'],
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    xAxis: {
      boundaryGap: false,
      type: 'category',
    },
    yAxis: {
      show: false,
    },
    series: [
      {
        name: 'Patients 2019',
        step: false,
        type: 'line',
        smooth: true,
        symbol: 'none',

        data: countVCardData.map((item) => ({ value: item.count, name: item.month })),
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#c565e7',
              },
              {
                offset: 0.6,
                color: '#d5ade4',
              },
              {
                offset: 1,
                color: '#f8e5ff',
              },
            ]),
          },
        },
      },
    ],
  };

  const OptionSocialMedia = {
    color: ['#c565e7'],
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    xAxis: {
      boundaryGap: false,
      type: 'category',
    },
    yAxis: {
      show: false,
    },
    series: [
      {
        name: 'Patients 2019',
        step: false,
        type: 'line',
        smooth: true,
        symbol: 'none',

        data:
          countSocialMediaData &&
          countSocialMediaData.reverse().map((item) => ({ value: item.count, name: item.month })),
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#c565e7',
              },
              {
                offset: 0.6,
                color: '#d5ade4',
              },
              {
                offset: 1,
                color: '#f8e5ff',
              },
            ]),
          },
        },
      },
    ],
  };
  const optionPie = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ({d}%)',
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      data: countPlatformData.map((data) => data.platform),
    },
    series: [
      {
        name: 'İşletim Sistemi',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: countPlatformData.map((item) => ({ value: item.total, name: item.platform })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        itemStyle: {
          normal: {
            borderWidth: 2,
            borderColor: '#fff',
          },
        },
      },
    ],
  };

  const OptionActivityChart = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
    },
    legend: {
      data: ['Profil ziyaretleri'],
    },
    xAxis: {
      boundaryGap: false,
      type: 'category',
      data: countPageData.map((data) => {
        const monthNames = [
          'Ocak',
          'Şubat',
          'Mart',
          'Nisan',
          'Mayıs',
          'Haziran',
          'Temmuz',
          'Ağustos',
          'Eylül',
          'Ekim',
          'Kasım',
          'Aralık',
        ];
        return monthNames[data.month - 1];
      }),
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} kişi',
      },
    },
    label: {
      fontSize: 12,
    },
    title: {
      textStyle: {
        fontSize: 16,
      },
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '15%',
      containLabel: true,
    },
    series: [
      {
        areaStyle: {
          color: 'rgba(245,255,206, 0.2)', // Dolgu rengi
          borderColor: 'rgba(0, 128, 255, 1)', // Kenarlık rengi
          borderWidth: 1, // Kenarlık kalınlığı
        },
        data: countPageData.map((data, index) => data.count),
        type: 'line',
        smooth: true,
      },
    ],
  };

  const authInfo : IAuthentication = new StateLoader().getAuthState();
  const getDashboardData = async () => {
    try {
      const params = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          UserID: authInfo.userApiKey,
        },
      };

      HTTPService.GET(`/Count/dashboard?userId=${authInfo.userID}`).then((response) => {
        console.log(response.data)
        const data =  response.data ;

        setDashboardData(data);
        const countPageData = data?.countPage;
        setCountPageData(countPageData.reverse());
        const countPlatformData = data?.countOS;
        setCountPlatformData(countPlatformData);
        const countSocialMediaData = data?.countSocial;
        setCountSocialMediaData(countSocialMediaData);
        const countVCardData = data?.countVCard;
        setCountVCardData(countVCardData.reverse());
        const countCityData = data?.countCity.map((item, index) =>
          item.city ? item : { city: 'Bilinmeyen', total: item.total , key:index}
        );
        setCountCityData(countCityData);
      })
      .catch((error) => {
        console.error('Dosya indirme hatası:', error);
      });


   
    } catch (error) {
      console.log(error);
    }
  };

  const pageData: IPageData = {
    title: 'Dashboard',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'Dashboard',
      },
    ],
  };

  return (
    <>
      <div>
        {countSocialMediaData || countVCardData || countPlatformData || countPageData ? (
          <Alert
            className='mb-4'
            message={null}
            type='success'
            showIcon
            description='Profilinize ait istatistikleri aşağıdan görüntüleyebilirsiniz.'
          />
        ) : (
          <Alert
            className='mb-4'
            message={null}
            type='warning'
            showIcon
            description='Profilinizede hiçbir veri kaydı bulunmamaktadır.'
          />
        )}
      </div>

      <div className='row'>
        <div className='col-12 col-xl-8'>
          <Card title='Profil Ziyaretleri' bodyStyle={{ padding: 0 }}>
            <ReactEcharts
              className='chart-container container-h-350'
              option={OptionActivityChart}
              style={{ height: '350px', width: '100%' }}
            />
          </Card>
        </div>

        <div className='col-12 col-xl-4'>
          <Card title='Ziyaretçi Lokasyonları' bodyStyle={{ padding: 0 }}>
            <Table
              dataSource={countCityData}
              columns={VisitorColumns}
              style={{ paddingLeft: 20, paddingRight: 20 }}
            />
          </Card>
        </div>

        <div className='col-12 col-xl-8'>
          <Card title='Ziyaretçi İşletim Sistemi Dağılımı' bodyStyle={{ padding: '0 30px 30px' }}>
            <ReactEcharts className='chart-container container-h-370' option={optionPie} />
          </Card>
        </div>

        <div className='col-12 col-xl-4'>
          <Card bodyStyle={{ padding: 0 }}>
            <div className='d-flex p-4 justify-content-between'>
              <div className='title-box d-flex flex-column'>
                <h5 style={{ fontSize: 18 }} className='title mb-1'>
                  Rehbere Eklenenler
                </h5>
              </div>
            </div>
            <ReactEcharts style={{ height: 140 }} option={OptionVCard} />
          </Card>

          <Card bodyStyle={{ padding: 0 }}>
            <div className='d-flex p-4 justify-content-between'>
              <div className='title-box d-flex flex-column'>
                <h5 style={{ fontSize: 18 }} className='title mb-1'>
                  Sosyal Medya Tıklamaları
                </h5>
              </div>
            </div>
            <ReactEcharts style={{ height: 140 }} option={OptionSocialMedia} />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
