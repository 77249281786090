import {Button} from "antd";
import HTTPService from "../../utils/makerequest";

export default function VCardGenerator({ data, company, userLanguage}) {
    const downloadTxtFile = vcfText => {
        const element = document.createElement("a");
        const file = new Blob([vcfText], { type: "text/plain;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = `${data?.userFirstName}_${data?.userLastName}.vcf`;
        document.body.appendChild(element);
        element.click();
    };

    const downloadVcfFile = vcfText => {
        const element = document.createElement("a");
        const file = new Blob([vcfText], { type: "text/vcard;charset=utf-8" });
        element.href = URL.createObjectURL(file);
        element.download = `${data?.userFirstName}_${data?.userLastName}.vcf`;
        document.body.appendChild(element);
        element.click();
    };

    const CreateVCard = () => {
        const vCardsJS = require("vcards-js");

        //create a new vCard
        const vCard = vCardsJS();

        //set properties
        vCard.firstName = data?.userFirstName;
        vCard.middleName = data?.userMiddleName;
        vCard.lastName = data?.userLastName;
        vCard.organization = company?.companyName;

        vCard.cellPhone = data?.userPersonalMobile;
        vCard.workPhone = data?.userPhoneHome;
        //vCard.birthday = new Date(1985, 0, 1);
        //vCard.birthday = data?.userBirthdate;
        /* vCard.email = data?.userEmailMain;*/
        vCard.workEmail = data?.userEmailMain;
        vCard.title = data?.userTitle;
        vCard.url = data?.userInstagramURL;
        vCard.mobilePhone = data?.userPhoneMobile2;
        vCard.homeAddress = data?.userAddress;
        //save to file
        // vCard.saveToFile("./eric-nesser.vcf");

        //get as formatted string
        // console.log(vCard.getFormattedString());
        return vCard.getFormattedString();
    };

    const downloadVCard=()=>{
        HTTPService.GET('/user/vcard?Guid='+data?.userID)
            .then((response) => {
              const blob = new Blob([response.data], { type: 'text/vcard' }); // Blob nesnesini oluşturun
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = data?.userFirstName+"-"+data?.userLastName+'.vcard'; // İndirilen dosyanın adını burada belirtin
              a.click();
              window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
              console.error('Dosya indirme hatası:', error);
            });
    }

    return (
        <div>
            {
                /*
            <Button onClick={() => downloadVcfFile(CreateVCard())}
                    style={{
                        backgroundColor: 'darkblue',
                        borderRadius: 500,
                        color: '#fff',
                        border: 'none',
                        width:'144px'

                    }}>
                Rehbere Ekle</Button>
                */
            }
            <Button onClick={() => downloadVCard()}
                    style={{
                        backgroundColor: 'darkblue',
                        borderRadius: 500,
                        color: '#fff',
                        border: 'none',
                        width:'144px'

                    }}>
                        {
                            userLanguage ? "Add Contact" : "Rehbere Ekle"
                        }
                </Button>
        </div>
    );
}
