import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.scss';
import { store, history } from './redux/store';
import App from './App';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
        <>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <App />
                </ConnectedRouter>
            </Provider>,
        </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can changes
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
