import React, { useEffect, useState } from 'react';

import {
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  Select,
  Space,
} from 'antd';

import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { DownOutlined } from '@ant-design/icons';
import HTTPService from '../../../utils/makerequest';
import { IUserUpdate } from '../../../interfaces/user';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { IAuthentication } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';

const { Option } = Select;

const UserForm: React.FC<any> = ({ props, data, company }) => {
  const [birthdate, setBirthdate] = useState(null);
  const [userRoleState, setUserRoleState] = useState(0);

  const setNewRole = (e) => {

    setUserRoleState(e);
  };

  function handleDateChange(date, dateString) {
    setBirthdate(moment(dateString));
  }

  const authInfo : IAuthentication = new StateLoader().getAuthState();
  const post = (e) => {
    if (company === null) {
      toast.error('Şirket seçilmedi.');
    } else {
      let user: IUserUpdate = [
        {
          userID:"a", 
          userTitle: e.Title,
          userFirstName: e.FirstName,
          userMiddleName: e.MiddleName,
          userLastName: e.LastName,
          userWorkMobile: e.workMobile,
          userPhoneWorkExtension: e.workExt,
          userPersonalMobile: e.mobilePhone,
          userPhoneMobile2: e.additionalPhone,
          userPhoneHome: e.homePhone,
          userEmailMain: e.Email,
          userEmailAdditional: e.AdditionalEmail,
          userAddress: e.Address,
          userBirthdate: birthdate,
          userLinkedinURL: e.LinkedinURL,
          userInstagramURL: e.InstagramURL,
          userFacebookURL: e.FacebookURL,
          userTwitterUrl: e.TwitterURL,
          userSocialMediaURL: e.SocialMediaURL,
          userCompanyID: company,
          userApiKey: authInfo.userApiKey,
          userRole: userRoleState,
          //"userPictureURL": img
        },
      ] as IUserUpdate;
console.log(user);
      user['userCompanyID'] = company;

      HTTPService.POST('/user/json', user)
        .then((res) => {
          if (res.status === 201) {
            toast.success('Kayıt Başarılı');
            setTimeout(function(){
              window.location.replace("/vertical/user-list");
          }, 1000);
          } else {
            toast.error('Kayıt başarısız');
          }
          res.json();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Form
      layout='vertical'
      onFinish={post}
      fields={[
        {
          name: ['Title'],
          value: data.userTitle,
        },
        {
          name: ['FirstName'],
          value: data.userFirstName,
        },
        {
          name: ['MiddleName'],
          value: data.userMiddleName,
        },
        {
          name: ['LastName'],
          value: data.userLastName,
        },
        {
          name: ['birthday'],
          value: data.userBirthdate,
        },
        {
          name: ['Email'],
          value: data.userEmailMain,
        },
        {
          name: ['userEmailAdditional'],
          value: data.userEmailAdditional,
        },
        {
          name: ['mobilePhone'],
          value: data.userPersonalMobile,
        },
        {
          name: ['additionalPhone'],
          value: data.userPhoneMobile2,
        },
        {
          name: ['homePhone'],
          value: data.userPhoneHome,
        },
        {
          name: ['Address'],
          value: data.userAddress,
        },
        {
          name: ['LinkedinURL'],
          value: data.userLinkedinURL,
        },
        {
          name: ['InstagramURL'],
          value: data.userInstagramURL,
        },
        {
          name: ['FacebookURL'],
          value: data.userFacebookURL,
        },
        {
          name: ['TwitterURL'],
          value: data.userTwitterUrl,
        },
        {
          name: ['SocialMediaURL'],
          value: data.userSocialMediaURL,
        },
        {
          name: ['userRole'],
          value: data.userRole,
        },
      ]}>
      <Form.Item
        rules={[{ required: false, message: 'Lütfen Rol Seçiniz.' }]}
        style={{
          display: 'inline-block',
          width: 'calc(50% - 12px)',
        }}
        initialValue={0}
        name='userRole'>
        <div className='ant-col ant-form-item-label'>
          <label title='Rol'>Rol</label>
        </div>
        <Select onSelect={setNewRole} value={userRoleState}>
          <Option value={0}>Üye</Option>
          <Option value={1}>Editör</Option>
          <Option value={2}>Yönetici</Option>
        </Select>
      </Form.Item>
      <Form.Item label='Ünvan' name='Title'>
        <Input placeholder='Doktor' />
      </Form.Item>

      <Form.Item
        label='Ad'
        name='FirstName'
        rules={[
          { required: true, message: 'Lütfen adınızı girin.' },
          { type: 'string', min: 3, message: 'Ad en az 3 karakter olmalıdır' },
        ]}>
        <Input placeholder='Mehmet' />
      </Form.Item>

      <Form.Item
        label='Ortanca Ad'
        name='MiddleName'
        rules={[
          { required: false, message: 'Ortanca Ad girin.' },
          { type: 'string', min: 2, message: 'Ortanca Ad en az 2 karakter olmalıdır' },
        ]}>
        <Input placeholder='Ali' />
      </Form.Item>

      <Form.Item
        label='Soyad'
        name='LastName'
        rules={[
          { required: true, message: 'Lütfen soyadınızı girin.' },
          { type: 'string', min: 3, message: 'Soyad en az 3 karakter olmalıdır' },
        ]}>
        <Input placeholder='Yılmaz' />
      </Form.Item>

      <Form.Item className='mb-0'>
        <Form.Item
          label='Doğum Yılı'
          style={{
            display: 'inline-block',
            width: 'calc(50% - 12px)',
          }}>
          <Space direction='horizontal'>
            <DatePicker
              name='userBirthdate'
              onChange={handleDateChange}
              value={birthdate}
              picker='date'
              style={{
                display: 'flex',
                width: '200%',
              }}
            />
          </Space>
        </Form.Item>
      </Form.Item>

      <Form.Item
        label='E-posta Adresi'
        name='Email'
        rules={[
          { required: true, message: 'Lütfen e-posta adresinizi girin.' },
          { type: 'email', message: 'Lütfen geçerli bir e-posta adresi girin.' },
        ]}>
        <Input placeholder='name@company.com' />
      </Form.Item>

      <Form.Item
        label='Ek E-posta Adresi'
        name='AdditionalEmail'
        rules={[
          { required: false, message: 'Lütfen e-posta adresinizi girin.' },
          { type: 'email', message: 'Lütfen geçerli bir e-posta adresi girin.' },
        ]}>
        <Input placeholder='name@company.com' />
      </Form.Item>

      <Form.Item
        label='Telefon Numarası'
        name='mobilePhone'
        rules={[
          { required: false, message: 'Lütfen telefon numaranızı girin.' },
          { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
        ]}>
        <Input placeholder='+90XXXXXXXXXX' />
      </Form.Item>

      <Form.Item
        label='Ek Telefon numarası'
        name='additionalPhone'
        rules={[
          { required: false, message: 'Lütfen telefon numaranızı girin.' },
          { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
        ]}>
        <Input placeholder='+90XXXXXXXXXX' />
      </Form.Item>

      <Form.Item
        label='İş Telefonu numarası'
        name='homePhone'
        rules={[
          { required: false, message: 'Lütfen telefon numaranızı girin.' },
          { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
        ]}>
        <Input placeholder='+90XXXXXXXXXX' />
      </Form.Item>

      <Form.Item
        label='Adres'
        name='Address'
        rules={[{ required: false, min: 15, message: 'Lütfen en az 15 karakter giriniz.' }]}>
        <Input.TextArea
          placeholder='Atatürk Mahallesi İnönü Caddesi Bina No: 23 Daire No: 5 06540 Çankaya/Ankara Türkiye'
        />
      </Form.Item>

      <Form.Item
        label='LinkedIn'
        name='LinkedinURL'
        rules={[
          {
            required: false,
            min: 10,
            message: 'Lütfen geçerli bir LinkedIn URL adresinizi giriniz.',
          },
          // {
          //   pattern: /^(www\.)?linkedin\.com\/[a-zA-Z0-9._-]+$/i,
          //   message: 'Lütfen geçerli bir LinkedIn URL adresi giriniz. (www.linkedin.com/username)',
          // },
        ]}>
        <Input placeholder='www.linkedin.com/username' />
      </Form.Item>

      <Form.Item
        label='Instagram'
        name='InstagramURL'
        rules={[
          {
            required: false,
            min: 10,
            message: 'Lütfen geçerli bir Instagram URL adresinizi giriniz.',
          },
          // {
          //   pattern: /^(www\.)?instagram\.com\/[a-zA-Z0-9._-]+$/i,
          //   message:
          //     'Lütfen geçerli bir Instagram URL adresi giriniz. (www.instagram.com/username)',
          // },
        ]}>
        <Input placeholder='www.instagram.com/username' />
      </Form.Item>

      <Form.Item
        label='Facebook'
        name='FacebookURL'
        rules={[
          {
            required: false,
            min: 10,
            message: 'Lütfen geçerli bir Facebook URL adresinizi giriniz.',
          },
          // {
          //   pattern: /^(www\.)?facebook\.com\/[a-zA-Z0-9._-]+$/i,
          //   message: 'Lütfen geçerli bir Facebook URL adresi giriniz. (www.facebook.com/username)',
          // },
        ]}>
        <Input placeholder='www.facebook.com/username' />
      </Form.Item>

      <Form.Item
        label='Twitter'
        name='TwitterURL'
        rules={[
          {
            required: false,
            min: 10,
            message: 'Lütfen geçerli bir Twitter URL adresinizi giriniz.',
          },
          // {
          //   pattern: /^(www\.)?twitter\.com\/[a-zA-Z0-9._-]+$/i,
          //   message: 'Lütfen geçerli bir Twitter URL adresi giriniz. (www.twitter.com/username)',
          // },
        ]}>
        <Input placeholder='www.twitter.com/username' />
      </Form.Item>

      <Form.Item label='Özel URL' name='SocialMediaURL'>
        <Input placeholder='www.example.com/' />
      </Form.Item>

      <div className='d-flex justify-content-between'>
        <Button type='primary' htmlType='submit'>
          Ekle
        </Button>
      </div>
    </Form>
  );
};

const PageQuickAdd: React.FC<IPageProps> = (props) => {
  const { onSetPage } = props;

  const [profileData] = useState<IUserUpdate>({});
  const [companyData, setCompanyData] = useState([]);
  const [selectedItemName, setSelectedItemName] = useState('Yok');
  const [selectedItem, setSelectedItem] = useState(null);


  const pageData: IPageData = {
    title: 'Edit account',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'User Pages ',
        route: 'dashboard',
      },
      {
        title: 'Edit Account',
      },
    ],
  };

  useEffect(() => {
    onSetPage(pageData);
    fetchCompanyInfo();
  }, []);

  const fetchCompanyInfo = () => {
    HTTPService.GET('/Company/name').then((response) => {
      if (!response.data) {
        return;
        }

      let tmp = response.data;
      if (tmp.length < 1) {
        setCompanyData([]);
        return;
      }

      setCompanyData(tmp);
      setSelectedItem(tmp[0].companyID);
    });
  };

  const userMenu = (
    <Menu
      style={{
        width: '200px',
        fontSize: '22px',
      }}>
      {companyData.map((element, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              setSelectedItem(element.companyID);
              setSelectedItemName(element.companyName);
            }}>
            {element.companyName}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div className='row justify-content-center'>
      <div className='col col-12 col-xl-8'>
        <Card title='Yeni Kullanıcı Ekleme Ekranı'>
          <Space style={{ marginBottom: '20px' }}>
            <Dropdown overlay={userMenu}>
              <Button>
                Şirket Seçin: <DownOutlined />
              </Button>
            </Dropdown>
            {selectedItemName}
          </Space>
          <UserForm data={profileData} company={selectedItem} />
        </Card>
      </div>
    </div>
  );
};

export default PageQuickAdd;
