import React from 'react';
import { Button, Form, Input } from 'antd';

import {MailOutlined} from "@ant-design/icons";
import {toast} from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";

const PageEmailCode = () => {
    const email = localStorage.getItem("email");

    const sendPassword = (e) => {
        toast.loading('Loading...');
        HTTPService.LOGIN(`/Login/passReset2?email=${email}&emailCode=${e.emailCode}`).then((response) => {
            if(response.status === 200 ){
                localStorage.setItem("emailCode", e.emailCode);
                window.location.replace("/public/new-password");
            } else {
                toast.error("Yanlış e-posta kodu.")
            }
        }).catch((response) => {
            return;
        });
    }

    return (
        <>
            <div className='title-block d-flex flex-column mb-4 p-0'>
                <h5 className='form-title mb-4'>E-posta Kodu</h5>

                <span className='desc'>
            Lütfen mailde gönderdiğimiz e-posta kodunu eksiksiz bir şekilde giriniz. 
        </span>
            </div>

            <Form onFinish={sendPassword}>
                <Form.Item name="emailCode">
                    <div className='col'>
                        <Input
                            placeholder='E-posta Kodu'
                        />
                    </div>
                </Form.Item>
                <div className='col-sm-4 col-12'>
                    <Button style={{ width: '100%' }} type='primary' htmlType='submit'>
                        Gönder
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default PageEmailCode;
