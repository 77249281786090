import {IUser} from "../../interfaces/user";
import {REMOVE_USER, SET_USER, UPDATE_USER, UserActionTypes} from "./types";

const initialState: IUser = {} as IUser;

export const userReducer = (state: IUser = initialState, action: UserActionTypes) => {
    switch (action.type) {
        case SET_USER:
            return { ...action.payload };
        case UPDATE_USER:
            return { ...state, ...action.payload };
        case REMOVE_USER:
            return { ...initialState };
        default:
            return { ...state};
    }
};