import  {useEffect, useRef, useState} from "react";
import * as XLSX from "xlsx";
import {Alert, Button, Card, Dropdown, Menu, Space, Table} from "antd";
import uuid from "uuid/v4";
import HTTPService from "../../../utils/makerequest";
import {toast} from "react-hot-toast";
import {DeleteOutlined, DownloadOutlined} from "@ant-design/icons";
import {DownOutlined} from "@ant-design/icons";
import "../ImportUsers/parseExcel.css"

export const ParcelExcel = () => {

    const [fileName, setFileName] = useState(null);
    const [table, setTable] = useState();
    const [visible, setVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState('' | null);
    const [type, setType] = useState('');
    const fileInput = useRef(null);
    const [companyData, setCompanyData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemName, setSelectedItemName] = useState("");
    const [disabled, setDisabled] = useState(true);

    const columns = [
        {
            title: 'Ünvan',
            dataIndex: 'userTitle',
            key: 'userTitle'
        },
        {
            title: 'Ad',
            dataIndex: 'userFirstName',
            key: 'userFirstName'
        },
        {
            title: 'Ortanca Ad',
            dataIndex: 'userMiddleName',
            key: 'userMiddleName'
        },
        {
            title: 'Soyad',
            dataIndex: 'userLastName',
            key: 'userLastName'
        },
        {
            title: 'E-posta Adresi',
            dataIndex: 'userEmailMain',
            key: 'userEmailMain'
        },
        {
            title: 'İş Telefonu',
            dataIndex: 'userWorkMobile',
            key: 'userWorkMobile'
        },
        {
            title: 'Dahili İş Telefonu',
            dataIndex: 'userPhoneWorkExtension',
            key: 'userPhoneWorkExtension'
        },
        {
            title: 'Cep Telefonu',
            dataIndex: 'userPersonalMobile',
            key: 'userPersonalMobile'
        },
        {
            title: 'Cep Telefonu 2',
            dataIndex: 'userPhoneMobile2',
            key: 'userPhoneMobile2'
        },
        {
            title: 'Ev Telefonu',
            dataIndex: 'userPhoneHome',
            key: 'userPhoneHome'
        },
        {
            title: 'İkincil E-posta Adresi',
            dataIndex: 'userEmailAdditional',
            key: 'userEmailAdditional'
        },
        {
            title: 'Web Sitesi',
            dataIndex: 'userHomepageURL',
            key: 'userHomepageURL'
        },
        {
            title: 'Adres',
            dataIndex: 'userAddress',
            key: 'userAddress'
        },
        {
            title: 'Doğum Yılı',
            dataIndex: 'userBirthdate',
            key: 'userBirthdate'
        },
        {
            title: 'Linkedin URL',
            dataIndex: 'userLinkedinURL',
            key: 'userLinkedinURL'
        },
        {
            title: 'Instagram URL',
            dataIndex: 'userInstagramURL',
            key: 'userInstagramURL'
        },
        {
            title: 'Facebook URL',
            dataIndex: 'userFacebookURL',
            key: 'userFacebookURL'
        },
        {
            title: 'Twitter URL',
            dataIndex: 'userTwitterUrl',
            key: 'userTwitterUrl'
        },
        {
            title: 'SocialMedia URL',
            dataIndex: 'userSocialMediaURL',
            key: 'userSocialMediaURL'
        },
        {
            title: 'Twitter URL',
            dataIndex: 'userTwitterUrl',
            key: 'userTwitterUrl'
        }
    ]


    useEffect(() => {
        fetchCompanyInfo();
    }, [])

    const handleClose = () => {
        setVisible(false);
    };

    const handleFile = async (e) => {
        const file = e.target.files[0];
        if(file) {
            setFileName(file.name);

            const data = await file.arrayBuffer();
            const workbook = XLSX.read(data);

            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let jsonData = XLSX.utils.sheet_to_json(worksheet);
            console.log(jsonData);
            const apiData = jsonData.map((item) => ({
                userTitle: item['Ünvan'],
                userFirstName: item['Ad'],
                userLastName: item['Soyad'],
                userEmailMain: item['E-posta Adresi'],
                userMiddleName: item['Ortanca Ad'],
                userWorkMobile: item['İş Telefonu'],
                userPhoneWorkExtension: item['Dahili İş Telefonu'],
                userPersonalMobile: item['Cep Telefonu'],
                userPhoneMobile2: item['Cep Telefonu 2'],
                userPhoneHome: item['Ev Telefonu'],
                userEmailAdditional: item['İkincil E-posta Adresi'],
                userHomepageURL: item['Web Sitesi'],
                userAddress: item['Adres'],
                userBirthdate: item['Doğum Yılı'],
                userLinkedinURL: item['Linkedin URL'],
                userInstagramURL: item['Instagram URL'],
                userFacebookURL: item['Facebook URL'],
                userTwitterUrl: item['Twitter URL'],
                userSocialMediaURL: item['Sosyal Medya URL'],
              }));
           
              const newData = apiData.map(item => {
                const birthdate = new Date((item.userBirthdate - 25569) * 86400 * 1000);
                const formattedBirthdate = `${birthdate.getDate()}.${birthdate.getMonth() + 1}.${birthdate.getFullYear()}`;
                return {
                  ...item,
                  userBirthdate: formattedBirthdate
                };
              });
            setTable(newData);
         
            setDisabled(false);
        } else {
            toast.error("File has not uploaded.")
        }
    }

    const fetchCompanyInfo = () => {
        HTTPService.GET('/Company').then((response) => {
            if (!response.data) {
                return;
            }
            let tmp = response.data;
            if (tmp.length < 1) {
                setCompanyData([]);
                return;
            }

            setCompanyData(tmp);
        });
    }    

    const post2 = () => {
        if(selectedItem === null) {
            toast.error("Şirket seçilmedi.")
        } else {
            table.map((item) => {
                item["userCompanyID"] = selectedItem;
                item["userID"] = "a";
            })

           
            HTTPService.POST('/user/json', table)
            .then((res) => {
              if (res.status === 201) {
                setType('success');
                setErrorMessage('Başarıyla eklendi.');
              } else if (res.status === 400) {
                setType('error');
                setErrorMessage('Kullanıcılar eklenemedi!!');
              } else {
                setType('error');
                setErrorMessage('Bilinmeyen bir hata oluştu.');
              }
              setVisible(true);
            })
            .catch((error) => {
              setType('error');
              setErrorMessage('Bir hata oluştu: ' + error.message);
              setVisible(true);
            });
        }

    }

    const userMenu = (
        <Menu style={{
            width: "200px",
            fontSize: "22px",
        }}>
            {
                companyData.map((element) => {
                    return <Menu.Item
                        onClick={() => {
                            setSelectedItem(element.companyID)
                            setSelectedItemName(element.companyName)
                        }}>{element.companyName}</Menu.Item>;
                })
            }
        </Menu>
    );
    const scroll = { scrollbar: { width: 12 } };
    return(
        <>
            <Card title='Dosya Yükleyin (CSV veya XLSX)'>
                <div className='d-sm-flex align-items-center w-100 elem-list'>
                    <Button
                        type='primary'
                        htmlType='button'
                        ghost
                        onClick={() => fileInput.current.click()}>
                        Dosya yükleyin
                        <DownloadOutlined />
                    </Button>
                    <input ref={fileInput} hidden id="file1" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => handleFile(e)}   />
                    {fileName && (
                        <p>Dosya Adı: <span>{fileName}</span></p>
                    )}
                </div>
            </Card>
            {visible && (<Space direction="vertical" style={{ width: '100%', marginBottom: '20px' }}>
                    <Alert message={errorMessage}

                           type={type} showIcon closable
                           afterClose={handleClose} />
                </Space>
            )}
            <Card
                className="tableClass"
                bodyStyle={{ padding: 0 }}
                title={
                    <div className='card-header mb-4'>
                        <Space>
                            <Dropdown overlay={userMenu}>
                                <Button> Şirket Seçin: <DownOutlined /></Button>
                            </Dropdown>
                            {selectedItemName}
                        </Space>
                        <div className='actions d-flex'>
                            <Space>
                                <Button shape="round"
                                        icon={<DeleteOutlined />}
                                        size='middle'
                                        onClick={() => {
                                            setTable([])
                                            setFileName(null)
                                            let file = document.getElementById("file1");
                                            file.value = file.defaultValue;
                                        }}>
                                    Tabloyu Temizle
                                </Button>
                            </Space>
                        </div>
                    </div>
                }>
            </Card>
            <Card
                className="tableClass"
                bodyStyle={{ padding: 0 }}
                title={
                    <>
                        {table && (
                            <Table
                                pagination={false}
                                columns={columns}
                                dataSource={table}
                            />
                        )}
                    </>
                }>
            </Card>
            <Button disabled={disabled} onClick={post2}>Ekle</Button>

        </>
    )
}