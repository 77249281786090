import React, { useEffect, useState } from 'react';

import { Alert, Button, Card, Form, Input, InputNumber } from 'antd';
import { IPageData, IPageProps } from '../../interfaces/page-data';

import { toast } from 'react-hot-toast';
import { ISettings } from '../../interfaces/settings';
import HTTPService from '../../utils/makerequest';


const SettingsForm: React.FC<any> = ({ props, data }) => {
  
  const post = (e) => {
    let setting: ISettings = {
      mailAccount: e.mailAccount,
      mailName: e.mailName,
      mailPass: e.mailPass,
      port: e.port,
      host: e.host,
      credentials: e.credentials,
    } as ISettings;

    HTTPService.PUT( '/emailSettings', setting)
    .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          // 2xx durum kodları (Başarı durumları)
          toast.success('Ayarlar güncellendi.');
        } else if (res.status >= 400 && res.status < 500) {
          // 4xx durum kodları (İstemci hataları)
          res.json().then((data) => {
            toast.error(`Bir istemci hatası oluştu: ${data.message}`);
          }).catch(() => {
            toast.error('Bir istemci hatası oluştu.');
          });
        } else if (res.status >= 500 && res.status < 600) {
          // 5xx durum kodları (Sunucu hataları)
          res.json().then((data) => {
            toast.error(`Bir sunucu hatası oluştu: ${data.message}`);
          }).catch(() => {
            toast.error('Bir sunucu hatası oluştu.');
          });
        } else {
          // Diğer durum kodları
          toast.error('Bir hata oluştu.');
        }
      })
      .catch((error) => {
        toast.error('Bir hata oluştu!', error);
      });
  };

  return (
    <Form
      layout='vertical'
      onFinish={post}
      fields={[
        {
          name: ['mailAccount'],
          value: data.mailAccount,
        },
        {
          name: ['mailName'],
          value: data.mailName,
        },
        {
          name: ['port'],
          value: data.port,
        },
        {
          name: ['host'],
          value: data.host,
        },
        {
          name: ['credentials'],
          value: data.credentials,
        },
      ]}>
      <Form.Item
        label='Mail Hesabı'
        name='mailAccount'
        rules={[
          { required: true, message: 'Lütfen mail hesabı giriniz!' },
          { type: 'string', min: 4 },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label='Mail Adı'
        name='mailName'
        rules={[
          { required: true, message: 'Lütfen mail adı giriniz!' },
          { type: 'string', min: 4 },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label='Mail Parolası'
        name='mailPass'
        rules={[
          { required: true, message: 'Lütfen mail parolası giriniz!' },
          { type: 'string', min: 2 },
        ]}>
        <Input.Password />
      </Form.Item>

      <Form.Item
        label='Port'
        name='port'
        rules={[
            { required: true, message: 'Lütfen port bilgisi giriniz!', min: 1},
            { pattern: /^[0-9]+$/i, message: 'Uygun bir port numarası giriniz!' },
          ]}
       >
        <Input />
      </Form.Item>

      <Form.Item
        label='Sunucu'
        name='host'
        rules={[
          { required: true, message: 'Lütfen sunucu bilgisi giriniz!' },
          { type: 'string', min: 2 },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label='Kimlik Bilgileri'
        name='credentials'
        rules={[{ required: false }, { type: 'string', min: 2 }]}>
        <Input />
      </Form.Item>

      <div className='d-flex justify-content-between'>
        <Button type='primary' htmlType='submit'>
          Kaydet
        </Button>
      </div>
    </Form>
  );
};

const PageSettings: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<ISettings[]>([]);

  const pageData: IPageData = {
    title: 'Settings',
    loaded: true,
    breadcrumbs: [
      {
        title: 'Home',
        route: 'dashboard',
      },
      {
        title: 'User Pages ',
        route: 'dashboard',
      },
      {
        title: 'Edit Account',
      },
    ],
  };

  useEffect(() => {
    onSetPage(pageData);
    getSettings();
  }, []);

  const getSettings = () => {
    setLoading(true);
    HTTPService.GET(`/emailSettings`)
      .then((response) => {
        console.log(response)
        if (!response.data) {
          return;
        }
        console.log("asda2")
        console.dir(response.data);
        setSettings(response.data);
      })
      .catch(() => {
        console.log("222")
        setLoading(false);
      });
    return;
  };

  return (
    <>
      <Alert
        className='mb-4'
        message={null}
        type='success'
        showIcon
        description='Lütfen dikkatle okuyun, bu bir uyarı mesajıdır.'
      />
      {loading && (
        <Card title='Mail Ayarları'>
          <SettingsForm data={settings} />
        </Card>
      )}
    </>
  );
};

export default PageSettings;
