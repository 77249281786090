import React, { useEffect, useRef, useState } from 'react';
import {Avatar,Button,Card,Dropdown,Form,Input,Menu,Select,Space,Typography} from 'antd';
import '../EditCompany/EditCompany.css';
import { IPageData, IPageProps } from '../../../interfaces/page-data';
import { SaveOutlined, UserOutlined } from '@ant-design/icons';
import HTTPService from '../../../utils/makerequest';
import { toast } from 'react-hot-toast';
import { ImageBaseURL } from '../../../utils/Constants';
import { useParams } from 'react-router';
import { ICompany } from '../../../interfaces/company';
import imageCompression from 'browser-image-compression';
import { PlusOutlined } from '@ant-design/icons';
const { Option } = Select; 

//const WrappedPasswordForm = Form.create({ name: 'password' })(PasswordForm);

const CompanyForm: React.FC<any> = ({ props, companyData }) => {
    const [selectLanguage , setSelectLanguage] = useState<string>("TR");
    const [languageData, setLanguageData] = useState({
        TR: companyData.companyFooterTextTR,
        EN: companyData.companyFooterTextEN
      });

    const put = (e) => {
        const company: ICompany = {
            companyID : companyData.companyID,
            companyName: e.companyName,
            companyAddress: e.companyAddress,
            companyPhone1: e.companyPhone1,
            companyPhone2: e.companyPhone2,
            companyEmailMain: e.companyEmailMain,
            companyLinkedinURL: e.companyLinkedinURL,
            companyInstagramURL: e.companyInstagramURL,
            companySocialMediaURL: e.companySocialMediaURL,
            companyEmail: e.companyEmail,
            companyHomepageURL: e.companyHomepageURL,
            companyFooterTextTR: languageData.TR === undefined ? companyData.companyFooterTextTR : languageData.TR,
            companyFooterTextEN: languageData.EN === undefined ? companyData.companyFooterTextEN : languageData.EN,
        };

        
        HTTPService.PUT(`/Company/id`, company)
            .then((res) => {
                if (res.status == 200) {
                    // 2xx durum kodları (Başarı durumları)
                    toast.success('Şirket bilgileri güncellendi.');
                } 
                else 
                {
                    // Diğer durum kodları
                    toast.error('Bir hata oluştu.');
                }
            })
            .catch((error) => {
                toast.error('Bir hata oluştu!', error);
            });
    };

    return (
        <Form
            layout='vertical'
            onFinish={put}
            fields={[
                {
                    name: ['companyName'],
                    value: companyData.companyName,
                },
                {
                    name: ['companyAddress'],
                    value: companyData.companyAddress,
                },
                {
                    name: ['companyPhone1'],
                    value: companyData.companyPhone1,
                },
                {
                    name: ['companyPhone2'],
                    value: companyData.companyPhone2,
                },
                {
                    name: ['companyHomepageURL'],
                    value: companyData.companyHomepageURL,
                },
                {
                    name: ['companyEmailMain'],
                    value: companyData.companyEmailMain,
                },
                {
                    name: ['companyLinkedinURL'],
                    value: companyData.companyLinkedinURL,
                },
                {
                    name: ['companyInstagramURL'],
                    value: companyData.companyInstagramURL,
                },
                {
                    name: ['Address'],
                    value: companyData.userAddress,
                },
                {
                    name: ['LinkedinURL'],
                    value: companyData.userLinkedinURL,
                },
                {
                    name: ['InstagramURL'],
                    value: companyData.userInstagramURL,
                },
                {
                    name: ['FacebookURL'],
                    value: companyData.userFacebookURL,
                },
                {
                    name: ['companySocialMediaURL'],
                    value: companyData.companySocialMediaURL,
                },
                {
                    name: ['companyEmail'],
                    value: companyData.companyEmail,
                },
                {
                    name: ['companyFooterTextTR'],
                    value: companyData.companyFooterTextTR,
                },                {
                    name: ['companyFooterTextEN'],
                    value: companyData.companyFooterTextEN,
                }
            ]}>
            <Form.Item
                label='Şirket Adı'
                name='companyName'
                rules={[
                    { required: true, message: 'Lütfen şirket adı giriniz' },
                    { type: 'string', min: 3 },
                ]}>
                <Input placeholder='Altis' />
            </Form.Item>
            <Form.Item
                label='E-posta Adresi'
                name='companyEmailMain'
                >
                <Input placeholder='name@company.com' />
            </Form.Item>

            <Form.Item
                label='İkincil E-posta Adresi'
                name='companyEmail'
                rules={[
                    { required: false, message: 'Lütfen e-posta adresinizi girin.' },
                    { type: 'email', message: 'Lütfen geçerli bir e-posta adresi girin.' },
                ]}>
                <Input placeholder='name@company.com' />
            </Form.Item>

            <Form.Item
                label='Telefon Numarası'
                name='companyPhone1'
                rules={[
                    { required: true, message: 'Lütfen telefon numaranızı girin.' },
                    { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
                ]}>
                <Input placeholder='+90XXXXXXXXXX' />
            </Form.Item>

            <Form.Item
                label='İkincil Telefon Numarası'
                name='companyPhone2'
                rules={[
                    { required: false, message: 'Lütfen telefon numaranızı girin.' },
                    { pattern: /^\+?\d{0,13}$/, message: 'Lütfen geçerli bir telefon numarası girin.' },
                ]}>
                <Input placeholder='+90XXXXXXXXXX' />
            </Form.Item>

            <Form.Item
                label='Adres'
                name='companyAddress'
                rules={[
                    { required: true, message: 'Lütfen adres giriniz' },
                    { type: 'string', min: 10 },
                ]}>
                <Input.TextArea placeholder='71 Pilgrim Avenue Chevy Chase, MD 20815' />
            </Form.Item>

            <Form.Item
                label='Ana Sayfa'
                rules={[
                    { required: true, message: 'Lütfen web adresinizi girin.' },
                    {
                        pattern:
                            /^((https?:\/\/)?(www\.)?)\S+\.([a-z]{2,}|(com|net|org|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum))$/i,
                        message: 'Lütfen geçerli bir web adresi giriniz.',
                    },
                ]}
                name='companyHomepageURL'>
                <Input placeholder='https://www.website.com' />
            </Form.Item>

            <Form.Item label='LinkedIn' name='companyLinkedinURL'>
                <Input placeholder='www.linkedin.com/username' />
            </Form.Item>

            <Form.Item label='Instagram' name='companyInstagramURL'>
                <Input placeholder='www.instagram.com/username' />
            </Form.Item>

            <Form.Item label='Sosyal Medya URL' name='companySocialMediaURL'>
                <Input placeholder='www.example.com/' />
            </Form.Item>

            <Form.Item label="Footer Dil Seçimi">
                <Select 
                    onChange={(value) => {
                    setSelectLanguage(value)
                    }} 
                    value={selectLanguage}>
                    <Option value="TR">Türkçe</Option>
                    <Option value="EN">English</Option>
                </Select>
            </Form.Item>
            <Form.Item label={`Footer Yazısı(${selectLanguage})`} name={`companyFooterText${selectLanguage}`}>
                <Input     value={languageData[selectLanguage]} 
                    onChange={(e) => {
                    const updatedLanguageData = languageData;
                    updatedLanguageData[selectLanguage] = e.target.value;
                    setLanguageData(updatedLanguageData);
                    }}
                />
            </Form.Item>
            <div className='d-flex justify-content-between'>
                <Button danger ghost>
                    İptal
                </Button>

                <Button type='primary' htmlType='submit'>
                    Değişiklikleri Kaydet
                </Button>
            </div>
        </Form>
    );
};

const PageEditCompany: React.FC<IPageProps> = (props) => {
    const { onSetPage } = props;
    const [loading, setLoading] = useState(false);
    const fileInput1 = useRef(null);
    const footerInputRef = useRef(null);
    const fileInput3 = useRef(null);
    const userId = useParams<{ id?: string }>().id;
    const [companyData, setCompanyData] = useState<ICompany>({});
    
    interface IUpdatePhotoModel {
        companyId: string,
        imageGroup: number,
        imageFile: File,
        imageName?:string
    }

    const pageData: IPageData = {
        title: 'Edit company',
        loaded: true,
        breadcrumbs: [
            {
                title: 'Home',
                route: 'dashboard',
            },
            {
                title: 'User Pages ',
                route: 'dashboard',
            },
            {
                title: 'Edit Account',
            },
        ],
    };

    const onLogoChanged = (inputValue: any) => {
        const file: File = inputValue.target.files[0];
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
    };

    const onCoverChanged = (inputValue: any) => {
        const file: File = inputValue.target.files[0];
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
    };
    //footer resim kaldırma
    const onFooterChanged = (inputValue: any) => {
        const file: File = inputValue.target.files[0];
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
    };
    useEffect(() => {
        onSetPage(pageData);
        getCompanyDetails(userId);
    }, []);

    const getCompanyDetails = (id: string) => {
        let company: ICompany = null;
        setLoading(true);
        HTTPService.GET(`/company/id?id=${id}`)
            .then((response) => {
                if (!response.data) {
                    return;
                }
                let data = response.data;
                setCompanyData(data);

            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
        return company;
    };

    const handleFileRead = async (event, uploadtype) => {
        const imageFile: File = event.target.files[0];
        const maxSizeMB = 1;
        const options = {
            maxSizeMB,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
        var imageGroup;
        const compressedFile = await imageCompression(imageFile, options);
        if (uploadtype === 'companyLogoURL') {
            imageGroup = 0;
        }
        if (uploadtype === 'companyCoverURL') {
            imageGroup = 1;
        }
        if (uploadtype === 'companyFooterURL') {
            imageGroup = 2;
        }
        const imageData: IUpdatePhotoModel = {
            companyId: companyData.companyID,
            imageGroup: imageGroup,
            imageFile: compressedFile
        };
        const formData = new FormData();
        for (const [key, value] of Object.entries(imageData)) {
            formData.append(key, value);
        }
        try {
           HTTPService.PUT(`/Company/putImage`, formData)
                .then((res) => {
                    if (res.status === 200 || res.status === 201) {
                        toast.success('Görsel Güncellendi');
                    } else {
                        toast.error('Görsel Güncellenemedi');
                    }
                })
                .catch((error) => {
                    toast.error('There was an error!', error);
                });
        } catch (error) {
            console.error(error);
        }
    };



    const { Text } = Typography;

    function deleteImage(name,index) {
        const imageData = {
            companyId: companyData.companyID,
            imageName: name
        };
        const formData = new FormData();
        for (const [key, value] of Object.entries(imageData)) {
            formData.append(key, value);
        }
        
        HTTPService.DELETEForm( `/Company/deleteImage`, formData)
        .then((res) => {
            if (res.status === 200 || res.status === 201)
            {
                window.location.reload();
            }
            else
            {
                toast.error('Görsel Güncellenemedi');
            }
        })
        .catch((error) => {
            toast.error('There was an error!', error);
        });
        
    }
    function putSize(name,size,index) {
        const imageData = {
            companyId: companyData.companyID,
            imageName: name,
            imageSize: size
        };
        const formData = new FormData();
        for (const [key, value] of Object.entries(imageData)) {
            formData.append(key, value);
        }
        
        HTTPService.PUTForm( `/Company/putSize`, formData)
        .then((res) => {
            if (res.status === 200 || res.status === 201) {

               
            } else {
                toast.error('Boyut Güncellenemedi');
            }
        })
        .catch((error) => {
            toast.error('There was an error!', error);
        });
        
    }
    const handleFileFooter = async (event) => {
        const imageFile: File = event.target.files[0];
        const maxSizeMB = 1;
        const options = {
            maxSizeMB,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        };
        const compressedFile = await imageCompression(imageFile, options);
        
        const imageData: IUpdatePhotoModel = {
            companyId: companyData.companyID,
            imageGroup: 2,
            imageFile: compressedFile
        };
        const formData = new FormData();
        for (const [key, value] of Object.entries(imageData)) {
            formData.append(key, value);
        }
         try {

             HTTPService.POSTForm(`/Company/postImage`, formData)
                 .then((res) => {
                     if (res.status === 200 || res.status === 201) {
                        window.location.reload();
                     } else {
                         toast.error('Görsel Güncellenemedi');
                     }
                 })
                 .catch((error) => {
                     toast.error('There was an error!', error);
                 });
         } catch (error) {
             console.error(error);
         }
    };
    
    const menu = (index) => (
        <Menu onClick={({ key }) => selectSize(index, key)}>
          <Menu.Item key="1">1</Menu.Item>
          <Menu.Item key="2">2</Menu.Item>
          <Menu.Item key="3">3</Menu.Item>
        </Menu>
      );
      const selectSize = (index, size) => {
        console.log(index + " "+size)
        setCompanyData((prevCompanyData) => {
            const newCompanyData = { ...prevCompanyData };
            newCompanyData.companyFooterList[index].size = parseInt(size);
            return newCompanyData;
        });
        console.log(companyData)
        putSize(companyData.companyFooterList[index].imageId, size, index);
      };


      
    return (
        <div className='row justify-content-center'>
            <div className='col col-12 col-xl-8'>
                <Card title='Şirket Düzenleme Ekranı'>
                    <div className='row'>
                        <div className='col p-1'>
                            <div className='d-sm-flex align-items-center justify-content-center w-100 elem-list'>
                                <Space direction='vertical' size='small'>
                                    <p style={{ fontSize: '18px' }}>Şirket Logo Görseli</p>
                                </Space>

                                <Avatar src={ImageBaseURL + companyData?.companyLogoURL} size={120} className='mb-2' />

                                <Button
                                    type='primary'
                                    htmlType='button'
                                    ghost
                                    onClick={(e) => {
                                        fileInput1.current.click();
                                    }}>
                                    Fotoğrafı Değiştir
                                    <UserOutlined />
                                </Button>

                                <input
                                    ref={fileInput1}
                                    type='file'
                                    accept='image/*'
                                    onChange={(e) => {
                                        onLogoChanged(e);
                                        handleFileRead(e, 'companyLogoURL');
                                    }}
                                    hidden
                                />
                                <Text className='text-center' type='secondary'>
                                    En uygun çözünürlük: (300x300px)
                                </Text>
                            </div>
                        </div>
                        <div className='col p-1'>
                            <div className='d-sm-flex align-items-center justify-content-center  w-100 elem-list'>
                            </div>
                        </div>
                        <div className='col p-1'>
                            <div className='col d-sm-flex align-items-center justify-content-center w-100 elem-list'>
                                <Space direction='vertical' size='small'>
                                    <p style={{ fontSize: '18px' }}>Şirket Cover Görseli</p>
                                </Space>
                                <Avatar src={ImageBaseURL + companyData?.companyCoverURL} size={120} className='mb-2' />
                                <Button
                                    type='primary'
                                    htmlType='button'
                                    ghost
                                    onClick={(e) => {
                                        fileInput3.current.click();
                                    }}>
                                    Fotoğrafı Değiştir
                                    <UserOutlined />
                                </Button>
                                <input
                                    // src={companyData?.companyCoverURL || userCover}
                                    ref={fileInput3}
                                    type='file'
                                    accept='image/*'
                                    onChange={(e) => {
                                        onCoverChanged(e);
                                        handleFileRead(e, 'companyCoverURL');
                                    }}
                                    hidden
                                />
                                <Text className='text-center' type='secondary'>
                                    En uygun çözünürlük: (1600x800px)
                                </Text>
                            </div>
                        </div>
                    </div>
                </Card>



                <div className='row justify-content-center'> </div>
                <div className='col col-12 col-xl-8'></div>



                <Card title='Footer Düzenleme'>
                    <Text className='text-upper' type='secondary'>
                        En uygun çözünürlük: (1600x500px)
                    </Text>
                   
                    <div className='row mt-4'>
                        {/* {footerImages?.map((row, index) => (
                            <div className='col-md-4' key={index}>
                                <div className='d-sm-flex align-items-center justify-content-center w-100 elem-list'>
                                    <Avatar src={ImageBaseURL + row} size={120} className='mb-2' />
                                    <Button onClick={() => deleteImage(row,index)}> <i className="icofont-ui-delete "></i></Button>
                                    <Dropdown overlay={menu(index)}>
                                        <Button>{selectedSizes[index] || 'Size'}</Button>
                                    </Dropdown>
                                </div>
                            </div>
                        ))} */}
                        {companyData?.companyFooterList?.map((row, index) => (
                            <div className={`col-md-${row.size * 4}`}  key={index}>
                                <div className='d-sm-flex align-items-center justify-content-center w-100 elem-list'>
                                    <img src={ImageBaseURL + row.imageId} alt="" style={{width: `${row.size * 300}px`, height: "100px"}} className='mb-2' />
                                    <Button onClick={() => deleteImage(row.imageId,index)}> <i className="icofont-ui-delete "></i></Button>
                                    <Dropdown overlay={menu(index)}>
                                    <Button onClick={() => putSize(row.imageId, row.size, index)}>{row.size || 'Size'}</Button>
                                    </Dropdown>
                                </div>
                            </div>
                        ))}
                        <div className='col-md-4 d-flex flex-wrap justify-content-center align-items-center'>
            
                        <Button type="primary" onClick={() => footerInputRef.current.click()} icon={<PlusOutlined />} size="large">
                                Yeni Resim Ekle
                            </Button>
                            <input
                                ref={footerInputRef}
                                type='file'
                                accept='image/*'
                                onChange={(e) => {
                                    onFooterChanged(e);
                                    handleFileFooter(e);
                                }}
                                hidden
                            />
                            
                        </div>
                    </div>
                </Card>
                {loading && (
                    <Card title='Şirket Ekleme Ekranı'>
                        <CompanyForm companyData={companyData}/>
                    </Card>
                )}
            </div>
        </div>
    );
};

export default PageEditCompany;
