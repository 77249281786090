import { Button, Form, Input } from 'antd';
import {toast} from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { useParams } from 'react-router';


const PageNewPassword = () => {
    // const email = localStorage.getItem("email");
    //const emailCode = localStorage.getItem("emailCode");
    const userId = useParams<{ id?: string }>().id;

    const sendPassword = (e) => {
    toast.loading('Loading...');
    HTTPService.LOGIN(`/Login/passReset3?userCodeGuid=${userId}&newPass=${e.password}`)
    .then((response) => {
      if(response.status === 200 ){
        window.location.replace("/vertical/dashboard");
      } else {
          toast.error("Parola Hatası.")
      }
    }).catch((response) => {
      return;
    });
  }

  return (
    <>
      <div className='title-block d-flex flex-column mb-4 p-0'>
        <h5 className='form-title mb-4'>Yeni Parola Belirleme</h5>

        <span className='desc'>
          Yeni parolanızı aşağıdaki alana giriniz
        </span>
      </div>

      <Form onFinish={sendPassword}>
          <Form.Item name="password">
              <Input.Password
                  style={{marginLeft: "-14px"}}
                  bordered={false}
                  placeholder='Yeni Parola'
              />
          </Form.Item>
          <div className='col-sm-4 col-12'>
            <Button style={{ width: '100%' ,marginLeft: "-14px"}} type='primary' htmlType='submit'>
              Onayla
            </Button>
          </div>
      </Form>
    </>
  );
};

export default PageNewPassword;
