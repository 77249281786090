import React, { FormEvent } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { FormProps } from 'antd/es/form';
import {LockOutlined, MailOutlined, SolutionOutlined, UserOutlined} from "@ant-design/icons";

interface LoginFormProps {
  withLabels: boolean;
  form?: any;
}

const LoginForm: React.FC<LoginFormProps> = props => {
  const { withLabels, form } = props;

  const formItemLayout = {
    labelCol: withLabels
      ? {
          xs: { span: 24 },
          sm: { span: 6 }
        }
      : null,
    wrapperCol: withLabels
      ? {
          xs: { span: 24 },
          sm: { span: 18 }
        }
      : {
          xs: { span: 24 }
        }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: `', values);
      }
    });
  };

  return (
    <>
      <Form onFinish={handleSubmit} className='login-form' {...formItemLayout} labelAlign='left'>
        <Form.Item label={withLabels ? 'First name' : null} name='firstName'
                   rules={[{ required: true, message: <span className='error-message'>Please enter your first name</span> }]}>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='First name'
            />
        </Form.Item>
        <Form.Item label={withLabels ? 'Last name' : null} name='lastName'
        rules={[{ required: true, message: <span className='error-message'>Please enter your last name</span> }]}>
            <Input
              prefix={<SolutionOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Username'
            />
        </Form.Item>
        <Form.Item label={withLabels ? 'Email' : null} name='email'
        rules={[{ required: true, message: <span className='error-message'>Please input your email</span> }]}>
            <Input
              prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder='Username'
            />
        </Form.Item>
        <Form.Item label={withLabels ? 'Password' : null} name='password'
        rules={ [{ required: true, message: <span className='error-message'>Please input your password</span> }]}>
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type='password'
              placeholder='Password'
            />
        </Form.Item>
        <Form.Item>
          <Button htmlType='submit' className='login-form-button'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

//const WrappedLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

export { LoginForm };
