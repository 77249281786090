import React, { useEffect } from 'react';

import { Menu, Dropdown, Avatar } from 'antd';

import './Actions.scss';
import DefaultProfile from '../../../assets/content/default-profile.jpg'
import { IAuthentication } from '../../../interfaces/authentication';
import { StateLoader } from '../../../redux/StateLoader';

const Actions: React.FunctionComponent = () => {
 
  
  const stateLoader = new StateLoader();
  const authInfo :IAuthentication = stateLoader.getAuthState();
  
  useEffect(() => {
    if(!authInfo) {
      window.location.replace('/public/sign-in/');
      return ;
  }
}, )
  const accountMenu = (
    <Menu style={{ minWidth: '220px' }}>
      <Menu.Item key={'1'}>
        <a href={"/user/" + authInfo?.userID}>Profilim</a>
      </Menu.Item>

      <Menu.Item key={'2'}>
        <a href='https://www.altis.com.tr/'>Yardım</a>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key={'3'}>
        <span className='d-flex d-flex justify-content-between align-item-center'>
          <a className='d-flex w-100' href='/public/sign-in' onClick={() => stateLoader.removeAuthState()}>
            Çıkış Yap
          </a>
          <span className='d-flex align-items-center icofont-logout' />
        </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='actions'>
      {authInfo?.userFirstName}  {authInfo?.userMiddleName ? authInfo?.userMiddleName.charAt(0)+"." : ""} {authInfo?.userLastName} 
      <Dropdown overlay={accountMenu} trigger={['click']}>
        <div className='item'>
          <Avatar className='mr-1' src={authInfo?.userPictureURL || DefaultProfile} />
          <span className='icofont-simple-down' />
        </div>
      </Dropdown>
    </div>
  );
};

export default Actions;
