import React, { useState } from 'react';
import '../BaseLayout/BaseLayout.scss';
import './User.scss';
import {connect} from 'react-redux';
import {Route} from 'react-router';
import {toggleSidebar} from '../../redux/settings/actions';
import BaseLayout from '../BaseLayout/BaseLayout';
import {IPageData} from '../../interfaces/page-data';
import {IAppSettings} from '../../interfaces/settings';
import {AppState} from '../../redux/store';
import BasePage from '../../pages/BasePage/BasePage';
import {userRoutes} from '../../routes';
import {resetPageData, setPageData} from '../../redux/pages/actions';


interface UserLayoutProps {
  pageData: IPageData;
  settings: IAppSettings;
  onSidebarToggle: () => void;
  onSettingsReset: () => void;
  onSettingsSet: (data: IAppSettings) => void;
  onSettingsUpdate: (data: IAppSettings) => void;
  onSetPage: (data: IPageData) => void;
  onPageReset: () => void;
}

const UserLayout: React.FunctionComponent<UserLayoutProps> = props => {
  const { pageData, settings, onSidebarToggle, onPageReset, onSetPage } = props;
  const {boxed} = settings;
  return (
      <div className='layout horizontal'>
        <div className={`app-container ${boxed && 'boxed'}`}>
          <BaseLayout
              pageData={pageData}
              settings={settings}
              onPageReset={onPageReset}
              onSidebarToggle={onSidebarToggle}>
            {userRoutes.map((route, i) => {
              
              return (
                  <Route
                      exact
                      key={i}
                      path={`/user${route.path}`}
                      render={() => (
                          <BasePage>
                            <route.component onSetPage={onSetPage} key={i}/>
                          </BasePage>
                      )}
                  />
              );
            })}
          </BaseLayout>
        </div>

      </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  pageData: state.pageData,
  settings: state.settings
});

const mapDispatchToProps = dispatch => ({
  onSidebarToggle: () => dispatch(toggleSidebar()),
  onSetPage: (data: IPageData) => dispatch(setPageData(data)),
  onPageReset: () => dispatch(resetPageData())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserLayout);